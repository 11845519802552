// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Account, SocialLink, Feature, Language, Media, Attachment, Concept, MenuItem, ExtraItem, Category, Price, Choice, ChoiceGroup, Kiosk, KioskStatus, ScreenSaver, Banner, Order, OrderItem, Service, PaymentMethod, Timeline, AdminRole, AdminGroup, UserConcepts, Video } = initSchema(schema);

export {
  Account,
  SocialLink,
  Feature,
  Language,
  Media,
  Attachment,
  Concept,
  MenuItem,
  ExtraItem,
  Category,
  Price,
  Choice,
  ChoiceGroup,
  Kiosk,
  KioskStatus,
  ScreenSaver,
  Banner,
  Order,
  OrderItem,
  Service,
  PaymentMethod,
  Timeline,
  AdminRole,
  AdminGroup,
  UserConcepts,
  Video
};