import useAccount from "./useAccount";
import useConcept from "./useConcept";
import useFeature from "./useFeature";
import useLanguage from "./useLanguage";
import useSocialLink from "./useSocialLink";
import useAttachment from "./useAttachment";
import useMenuItem from "./useMenuItem";
import useCategory from "./useCategory";
import usePrice from "./usePrice";
import useChoice from "./useChoice";
import useChoiceGroup from "./useChoiceGroup";
import useMedia from "./useMedia";
import useScreenSaver from "./useScreenSaver";
import useBanner from "./useBanner";
import useOrder from "./useOrder";
import useOrderItem from "./useOrderItem";
import useKiosk from "./useKiosk";
import useKioskStatus from "./useKioskStatus";
import useService from "./useService";
import usePaymentMethod from "./usePaymentMethod";
import useTimeline from "./useTimeline";
import useAdminRole from "./useAdminRole";
import useAdminGroup from "./useAdminGroup";
import useVideo from "./useVideo";

const useResource = () => {
  const accountsApi = useAccount("accounts", "account");
  const conceptsApi = useConcept("concepts", "concept");
  const featuresApi = useFeature("features", "feature");
  const languagesApi = useLanguage("languages", "language");
  const socialLinksApi = useSocialLink("socialLinks", "socialLink");
  const attachmentsApi = useAttachment("attachments", "attachment");
  const menuItemApi = useMenuItem("menuItems", "menuItem");
  const categoryApi = useCategory("categories", "category");
  const priceApi = usePrice("prices", "price");
  const choiceApi = useChoice("choices", "choice");
  const choiceGroupApi = useChoiceGroup("choiceGroups", "choiceGroup");
  const mediaLibraryApi = useMedia("mediaLibrary", "media");
  const screenSaverApi = useScreenSaver("screenSavers", "screenSaver");
  const bannerApi = useBanner("banners", "banner");
  const ordersApi = useOrder("orders", "order");
  const orderItemssApi = useOrderItem("orderItems", "orderItem");
  const kiosksApi = useKiosk("kiosks", "kiosk");
  const kioskStatusApi = useKioskStatus("kioskStatuses", "kioskStatus");
  const servicesApi = useService("services", "service");
  const paymentMethodsApi = usePaymentMethod("paymentMethods", "paymentMethod");
  const timelinesApi = useTimeline("timelines", "timeline");
  const adminRolesApi = useAdminRole("adminRoles", "adminRole");
  const adminGroupsApi = useAdminGroup("adminGroups", "adminGroup");
  const videosApi = useVideo("videos", "video");

  return {
    ...videosApi,
    ...kiosksApi,
    ...kioskStatusApi,
    ...ordersApi,
    ...orderItemssApi,
    ...bannerApi,
    ...screenSaverApi,
    ...mediaLibraryApi,
    ...menuItemApi,
    ...accountsApi,
    ...conceptsApi,
    ...featuresApi,
    ...languagesApi,
    ...socialLinksApi,
    ...attachmentsApi,
    ...categoryApi,
    ...priceApi,
    ...choiceApi,
    ...choiceGroupApi,
    ...servicesApi,
    ...paymentMethodsApi,
    ...timelinesApi,
    ...adminRolesApi,
    ...adminGroupsApi,
  };
};

export default useResource;
