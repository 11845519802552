/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      logo {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      deleted
      createdAt
      createdByID
      createdByName
      callCenterEmployeesIds
      updatedAt
      _version
      _deleted
      _lastChangedAt
      accountLogoId
      __typename
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        domain
        siteTitle
        guestsCount
        tagline
        description
        siteAddress
        defaultLanguage
        languages
        features
        status
        socialLinks
        deleted
        createdAt
        createdByID
        createdByName
        callCenterEmployeesIds
        updatedAt
        _version
        _deleted
        _lastChangedAt
        accountLogoId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAccounts = /* GraphQL */ `
  query SyncAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        domain
        siteTitle
        guestsCount
        tagline
        description
        siteAddress
        defaultLanguage
        languages
        features
        status
        socialLinks
        deleted
        createdAt
        createdByID
        createdByName
        callCenterEmployeesIds
        updatedAt
        _version
        _deleted
        _lastChangedAt
        accountLogoId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const accountsByDomain = /* GraphQL */ `
  query AccountsByDomain(
    $domain: String!
    $createdAtDeleted: ModelAccountByDomainCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountsByDomain(
      domain: $domain
      createdAtDeleted: $createdAtDeleted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        domain
        siteTitle
        guestsCount
        tagline
        description
        siteAddress
        defaultLanguage
        languages
        features
        status
        socialLinks
        deleted
        createdAt
        createdByID
        createdByName
        callCenterEmployeesIds
        updatedAt
        _version
        _deleted
        _lastChangedAt
        accountLogoId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSocialLink = /* GraphQL */ `
  query GetSocialLink($id: ID!) {
    getSocialLink(id: $id) {
      id
      name
      icon
      slug
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSocialLinks = /* GraphQL */ `
  query ListSocialLinks(
    $filter: ModelSocialLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSocialLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        icon
        slug
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSocialLinks = /* GraphQL */ `
  query SyncSocialLinks(
    $filter: ModelSocialLinkFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSocialLinks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        icon
        slug
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const activeLinks = /* GraphQL */ `
  query ActiveLinks(
    $name: String!
    $createdAtDeleted: ModelSocialLinkByNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSocialLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeLinks(
      name: $name
      createdAtDeleted: $createdAtDeleted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        icon
        slug
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFeature = /* GraphQL */ `
  query GetFeature($id: ID!) {
    getFeature(id: $id) {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFeatures = /* GraphQL */ `
  query ListFeatures(
    $filter: ModelFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        icon
        slug
        precedence
        parent
        private
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFeatures = /* GraphQL */ `
  query SyncFeatures(
    $filter: ModelFeatureFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFeatures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        icon
        slug
        precedence
        parent
        private
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchFeatures = /* GraphQL */ `
  query SearchFeatures(
    $name: String!
    $createdAtDeleted: ModelFeatureByNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchFeatures(
      name: $name
      createdAtDeleted: $createdAtDeleted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        icon
        slug
        precedence
        parent
        private
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const activeFeatures = /* GraphQL */ `
  query ActiveFeatures(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeFeatures(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        icon
        slug
        precedence
        parent
        private
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLanguage = /* GraphQL */ `
  query GetLanguage($id: ID!) {
    getLanguage(id: $id) {
      id
      name
      code
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLanguages = /* GraphQL */ `
  query ListLanguages(
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLanguages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLanguages = /* GraphQL */ `
  query SyncLanguages(
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLanguages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        code
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const activeLanguages = /* GraphQL */ `
  query ActiveLanguages(
    $name: String!
    $createdAtDeleted: ModelLanguageByNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeLanguages(
      name: $name
      createdAtDeleted: $createdAtDeleted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        code
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMedia = /* GraphQL */ `
  query GetMedia($id: ID!) {
    getMedia(id: $id) {
      id
      accountID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      attachments
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMedia = /* GraphQL */ `
  query ListMedia(
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedia(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        attachments
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMedia = /* GraphQL */ `
  query SyncMedia(
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMedia(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        attachments
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAttachment = /* GraphQL */ `
  query GetAttachment($id: ID!) {
    getAttachment(id: $id) {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAttachments = /* GraphQL */ `
  query ListAttachments(
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAttachments = /* GraphQL */ `
  query SyncAttachments(
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttachments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getConcept = /* GraphQL */ `
  query GetConcept($id: ID!) {
    getConcept(id: $id) {
      id
      accountID
      name
      kiosks
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listConcepts = /* GraphQL */ `
  query ListConcepts(
    $filter: ModelConceptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        name
        kiosks
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncConcepts = /* GraphQL */ `
  query SyncConcepts(
    $filter: ModelConceptFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncConcepts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        name
        kiosks
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const accountConcepts = /* GraphQL */ `
  query AccountConcepts(
    $accountID: String!
    $createdAtDeleted: ModelConceptByAccountCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConceptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountConcepts(
      accountID: $accountID
      createdAtDeleted: $createdAtDeleted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        name
        kiosks
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMenuItem = /* GraphQL */ `
  query GetMenuItem($id: ID!) {
    getMenuItem(id: $id) {
      id
      conceptID
      kioskID
      name
      description
      image
      categoryID
      categoryName
      outOfStock
      requiredPoints
      symphonyID
      ratings
      prices
      choiceGroups
      enabled
      hasLimitedQuantity
      quantity
      autoRestock
      restockQuantity
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMenuItems = /* GraphQL */ `
  query ListMenuItems(
    $filter: ModelMenuItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenuItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        kioskID
        name
        description
        image
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        ratings
        prices
        choiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMenuItems = /* GraphQL */ `
  query SyncMenuItems(
    $filter: ModelMenuItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMenuItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        kioskID
        name
        description
        image
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        ratings
        prices
        choiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getExtraItem = /* GraphQL */ `
  query GetExtraItem($id: ID!) {
    getExtraItem(id: $id) {
      id
      conceptID
      kioskID
      name
      description
      image
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listExtraItems = /* GraphQL */ `
  query ListExtraItems(
    $filter: ModelExtraItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExtraItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        kioskID
        name
        description
        image
        price
        symphonyID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncExtraItems = /* GraphQL */ `
  query SyncExtraItems(
    $filter: ModelExtraItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncExtraItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        kioskID
        name
        description
        image
        price
        symphonyID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      conceptID
      kioskID
      name
      image
      precedence
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        kioskID
        name
        image
        precedence
        symphonyID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCategories = /* GraphQL */ `
  query SyncCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        kioskID
        name
        image
        precedence
        symphonyID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPrice = /* GraphQL */ `
  query GetPrice($id: ID!) {
    getPrice(id: $id) {
      id
      conceptID
      name
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPrices = /* GraphQL */ `
  query ListPrices(
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        name
        price
        symphonyID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPrices = /* GraphQL */ `
  query SyncPrices(
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPrices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        name
        price
        symphonyID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChoice = /* GraphQL */ `
  query GetChoice($id: ID!) {
    getChoice(id: $id) {
      id
      conceptID
      name
      price
      choiceGroupID
      choiceGroupName
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChoices = /* GraphQL */ `
  query ListChoices(
    $filter: ModelChoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        name
        price
        choiceGroupID
        choiceGroupName
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChoices = /* GraphQL */ `
  query SyncChoices(
    $filter: ModelChoiceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChoices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        name
        price
        choiceGroupID
        choiceGroupName
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChoiceGroup = /* GraphQL */ `
  query GetChoiceGroup($id: ID!) {
    getChoiceGroup(id: $id) {
      id
      conceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      choices
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChoiceGroups = /* GraphQL */ `
  query ListChoiceGroups(
    $filter: ModelChoiceGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChoiceGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        name
        required
        maxNumberOfChoices
        minNumberOfChoices
        precedence
        choices
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChoiceGroups = /* GraphQL */ `
  query SyncChoiceGroups(
    $filter: ModelChoiceGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChoiceGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        name
        required
        maxNumberOfChoices
        minNumberOfChoices
        precedence
        choices
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getKiosk = /* GraphQL */ `
  query GetKiosk($id: ID!) {
    getKiosk(id: $id) {
      id
      conceptID
      name
      screenSaver
      inService
      startTime
      closeTime
      type
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listKiosks = /* GraphQL */ `
  query ListKiosks(
    $filter: ModelKioskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKiosks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        name
        screenSaver
        inService
        startTime
        closeTime
        type
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncKiosks = /* GraphQL */ `
  query SyncKiosks(
    $filter: ModelKioskFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncKiosks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        name
        screenSaver
        inService
        startTime
        closeTime
        type
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getKioskStatus = /* GraphQL */ `
  query GetKioskStatus($id: ID!) {
    getKioskStatus(id: $id) {
      id
      kioskID
      upTimeStart
      upTimeEnd
      totalUpTime
      calculatedOffTime
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listKioskStatuses = /* GraphQL */ `
  query ListKioskStatuses(
    $filter: ModelKioskStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKioskStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        kioskID
        upTimeStart
        upTimeEnd
        totalUpTime
        calculatedOffTime
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncKioskStatuses = /* GraphQL */ `
  query SyncKioskStatuses(
    $filter: ModelKioskStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncKioskStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        kioskID
        upTimeStart
        upTimeEnd
        totalUpTime
        calculatedOffTime
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getScreenSaver = /* GraphQL */ `
  query GetScreenSaver($id: ID!) {
    getScreenSaver(id: $id) {
      id
      kioskID
      name
      image
      video
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listScreenSavers = /* GraphQL */ `
  query ListScreenSavers(
    $filter: ModelScreenSaverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScreenSavers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        kioskID
        name
        image
        video
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncScreenSavers = /* GraphQL */ `
  query SyncScreenSavers(
    $filter: ModelScreenSaverFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncScreenSavers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        kioskID
        name
        image
        video
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBanner = /* GraphQL */ `
  query GetBanner($id: ID!) {
    getBanner(id: $id) {
      id
      kioskID
      title
      image
      imageUrl
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBanners = /* GraphQL */ `
  query ListBanners(
    $filter: ModelBannerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBanners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        kioskID
        title
        image
        imageUrl
        description
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBanners = /* GraphQL */ `
  query SyncBanners(
    $filter: ModelBannerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBanners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        kioskID
        title
        image
        imageUrl
        description
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      kioskID
      symphonyID
      checkNumber
      status
      reason
      totalAmount
      tenderId
      paymentType
      orderTypeId
      orderType
      employeeId
      rvcId
      orderItems
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        kioskID
        symphonyID
        checkNumber
        status
        reason
        totalAmount
        tenderId
        paymentType
        orderTypeId
        orderType
        employeeId
        rvcId
        orderItems
        referenceId
        cardHolderNumber
        cardHolderName
        cardSchemeName
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrders = /* GraphQL */ `
  query SyncOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        kioskID
        symphonyID
        checkNumber
        status
        reason
        totalAmount
        tenderId
        paymentType
        orderTypeId
        orderType
        employeeId
        rvcId
        orderItems
        referenceId
        cardHolderNumber
        cardHolderName
        cardSchemeName
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderItem = /* GraphQL */ `
  query GetOrderItem($id: ID!) {
    getOrderItem(id: $id) {
      id
      menuItemID
      symphonyID
      name
      price
      quantity
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOrderItems = /* GraphQL */ `
  query ListOrderItems(
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        menuItemID
        symphonyID
        name
        price
        quantity
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrderItems = /* GraphQL */ `
  query SyncOrderItems(
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        menuItemID
        symphonyID
        name
        price
        quantity
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      name
      image
      imageUrl
      enabled
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        imageUrl
        enabled
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncServices = /* GraphQL */ `
  query SyncServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        image
        imageUrl
        enabled
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPaymentMethod = /* GraphQL */ `
  query GetPaymentMethod($id: ID!) {
    getPaymentMethod(id: $id) {
      id
      name
      image
      imageUrl
      enabled
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPaymentMethods = /* GraphQL */ `
  query ListPaymentMethods(
    $filter: ModelPaymentMethodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentMethods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        imageUrl
        enabled
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPaymentMethods = /* GraphQL */ `
  query SyncPaymentMethods(
    $filter: ModelPaymentMethodFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPaymentMethods(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        image
        imageUrl
        enabled
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTimeline = /* GraphQL */ `
  query GetTimeline($id: ID!) {
    getTimeline(id: $id) {
      id
      actionName
      oldStatus
      newStatus
      userId
      kioskId
      categoryId
      menuItemId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTimelines = /* GraphQL */ `
  query ListTimelines(
    $filter: ModelTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimelines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        actionName
        oldStatus
        newStatus
        userId
        kioskId
        categoryId
        menuItemId
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTimelines = /* GraphQL */ `
  query SyncTimelines(
    $filter: ModelTimelineFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTimelines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        actionName
        oldStatus
        newStatus
        userId
        kioskId
        categoryId
        menuItemId
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const activeTimelines = /* GraphQL */ `
  query ActiveTimelines(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeTimelines(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        actionName
        oldStatus
        newStatus
        userId
        kioskId
        categoryId
        menuItemId
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminRole = /* GraphQL */ `
  query GetAdminRole($id: ID!) {
    getAdminRole(id: $id) {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminRoles = /* GraphQL */ `
  query ListAdminRoles(
    $filter: ModelAdminRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminRoles = /* GraphQL */ `
  query SyncAdminRoles(
    $filter: ModelAdminRoleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const activeRoles = /* GraphQL */ `
  query ActiveRoles(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeRoles(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminGroup = /* GraphQL */ `
  query GetAdminGroup($id: ID!) {
    getAdminGroup(id: $id) {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminGroups = /* GraphQL */ `
  query ListAdminGroups(
    $filter: ModelAdminGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        roles
        users
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminGroups = /* GraphQL */ `
  query SyncAdminGroups(
    $filter: ModelAdminGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        roles
        users
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserConcepts = /* GraphQL */ `
  query GetUserConcepts($id: ID!) {
    getUserConcepts(id: $id) {
      id
      defaultConcept
      concepts
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUserConcepts = /* GraphQL */ `
  query ListUserConcepts(
    $filter: ModelUserConceptsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        defaultConcept
        concepts
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserConcepts = /* GraphQL */ `
  query SyncUserConcepts(
    $filter: ModelUserConceptsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserConcepts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        defaultConcept
        concepts
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVideo = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      accountID
      name
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listVideos = /* GraphQL */ `
  query ListVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        name
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVideos = /* GraphQL */ `
  query SyncVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        name
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
