import { Suspense } from "react";
import Backdrop from "@mui/material/Backdrop";

import ContentLoader from "../ContentLoader";
import useApp from "../../../hooks/useApp";
import Box from "@mui/material/Box";
import CreateModal from "../CreateModal";
import DeleteResource from "../DeleteResource";
import DateRangePicker from "../DateRangePicker";
import PriceModal from "../PriceModal";
import ChoiceGroupModal from "../ChoiceGroupModal";
import ChoiceModal from "../ChoiceModal";
import { useSelector } from "react-redux";

const MainModal: React.FC = () => {
  const modalOpen = useSelector((state: any) => state.app.modalOpen);
  const modalContent = useSelector((state: any) => state.app.modalContent);
  const modalContentId = useSelector((state: any) => state.app.modalContentId);
  const { hideDeletePopup } = useApp();

  const renderModalContent = () => {
    if (typeof modalContent === "object") {
      const { slug } = modalContent;

      if (modalContentId === "new") return <CreateModal slug={slug} />;
      if (modalContentId === "date")
        return (
          <DateRangePicker title={slug} selection={modalContent.resourceId} />
        );
      if (modalContentId === "PriceModal")
        return (
          <PriceModal menuItemId={slug} priceId={modalContent.resourceId} />
        );

      if (modalContentId === "ChoiceModal")
        return (
          <ChoiceModal
            choiceGroupId={slug}
            choiceId={modalContent.resourceId}
          />
        );

      if (modalContentId === "ChoiceGroupModal")
        return (
          <ChoiceGroupModal
            menuItemId={slug}
            choiceGroupId={modalContent.resourceId}
          />
        );

      if (
        modalContent.resourceId &&
        (modalContentId === "delete" || modalContentId === "restore")
      )
        return (
          <DeleteResource
            slug={slug}
            resourceId={modalContent.resourceId}
            action={modalContentId}
          />
        );
    }

    return modalContent;
  };

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        alignItems: "flex-start",
      }}
      open={modalOpen}
    >
      <Box
        sx={{
          position: "absolute",
          inset: 0,
          zIndex: 0,
          cursor: "pointer",
        }}
        onClick={hideDeletePopup}
      />
      <Suspense fallback={<ContentLoader />}>{renderModalContent()}</Suspense>
    </Backdrop>
  );
};

export default MainModal;
