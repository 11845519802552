import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch } from "react-redux";
import { setListing, setSelected } from "../store/ducks/orderItem";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { OrderItem } from "../models";
import { CreateOrderItemInput } from "../models/api";
import {
  CreateVariables,
  ListingVariables,
  OrderBulkTrashVariables,
  GetVariables,
  UpdateVariables,
  Option,
} from "../models/app";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetch(params: ListingVariables, order: any) {
    const { searchText, startIndex, limit } = params;

    try {
      
      const listing = await DataStore.query(
        OrderItem as any,
        (model: any) => {
          model.deleted("eq", "0");

          if (searchText.length > 0)
            model.name("contains", searchText.toLowerCase());

          console.log({order})
          if(order && order.orderItems?.length > 0){
            model.or((model: any) => {
              for (let filter of order.orderItems) {
                model.id("eq", filter);
              }
            });
          }

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );
      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;

    try {
      const single: OrderItem | undefined = await DataStore.query(OrderItem as any, id);

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateOrderItemInput = {
        symphonyID: data.symphonyID,
        menuItemID: data.menuItemID,
        name: data.name,
        price: parseInt(data.price),
        quantity: parseInt(data.quantity),
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      const model = await DataStore.save(new OrderItem(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);
      return model;
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: UpdateVariables) {
    const { id, data } = params;

    try {
      const original = await get({ id });

      await DataStore.save(
        OrderItem.copyOf(original!, (updated) => {
          updated.symphonyID = data.symphonyID
            ? data.symphonyID
            : original!.symphonyID;
            updated.name = data.name ? data.name : original!.name;
          updated.price = data.price ? data.price : original!.price;
          updated.quantity = data.quantity ? data.quantity : original!.quantity;
          updated.menuItemID = data.menuItemID ? data.menuItemID : original!.menuItemID;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        OrderItem.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: OrderBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: GetVariables) {
    const { id } = params;

    try {
      await DataStore.delete(id as any);

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: OrderItem[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.symphonyID!, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "price",
      numeric: false,
      disablePadding: false,
      label: "Price",
    },
    {
      id: "quantity",
      numeric: false,
      disablePadding: false,
      label: "Quantity",
    },

    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "price", "quantity"];

  const api: any = {};

  api[`${listingName}Model`] = OrderItem as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: OrderItem[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));

  return api;
};

export default useResource;
