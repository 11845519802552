import { FC } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { capitalizeFirstLetter } from "../../../helpers/utils";
import TextEditor from "../TextEditor";
import Checkboxes from "../Checkboxes";
import ImageField from "../ImageField";

interface Props {
  name: string;
  label: string;
  type: string;
  autoFocus: boolean;
  defaultValue: any;
  register: any;
  setValue: any;
  errors: any;
  control: any;
  options?: any;
  disabled?: boolean;
  hidden?: any;
  button?: any;
  icon?: any;
  onClick?: any;
}

const FormField: FC<Props> = ({
  name,
  label,
  type,
  autoFocus,
  defaultValue,
  register,
  setValue,
  errors,
  control,
  options,
  disabled,
  hidden,
  button,
  icon,
  onClick,
}) => {
  const renderField = () => {
    switch (type) {
      case "textField":
        return (
          <TextField
            fullWidth
            size="small"
            autoFocus={autoFocus}
            defaultValue={defaultValue}
            placeholder={capitalizeFirstLetter(name)}
            {...register(name)}
            error={errors[name] ? true : false}
            disabled={disabled}
            hidden={hidden}
          />
        );
      case "password":
        return (
          <TextField
            fullWidth
            size="small"
            type="password"
            autoFocus={autoFocus}
            placeholder={capitalizeFirstLetter(name)}
            defaultValue={defaultValue}
            {...register(name)}
            error={errors[name] ? true : false}
            disabled={disabled}
            hidden={hidden}
          />
        );
      case "number":
        return (
          <TextField
            fullWidth
            size="small"
            type="number"
            defaultValue={defaultValue}
            placeholder={capitalizeFirstLetter(name)}
            {...register(name)}
            error={errors[name] ? true : false}
            inputProps={{ min: 0 }}
            disabled={disabled}
            hidden={hidden}
          />
        );
      case "phoneNumber":
        return (
          <TextField
            fullWidth
            size="small"
            type="number"
            defaultValue={defaultValue}
            placeholder={capitalizeFirstLetter(name)}
            {...register(name)}
            error={errors[name] ? true : false}
            disabled={disabled}
            hidden={hidden}
          />
        );
      case "date":
        return (
          <TextField
            fullWidth
            size="small"
            type="date"
            defaultValue={defaultValue}
            placeholder={capitalizeFirstLetter(name)}
            {...register(name)}
            error={errors[name] ? true : false}
            disabled={disabled}
            hidden={hidden}
          />
        );
      case "select":
        return options ? (
          <TextField
            select
            fullWidth
            size="small"
            autoFocus={autoFocus}
            defaultValue={defaultValue}
            {...register(name)}
            error={errors[name] ? true : false}
            disabled={disabled}
            hidden={hidden}
          >
            {options.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        ) : null;
      case "checkboxes":
        return options ? (
          <Checkboxes
            label={label}
            name={name}
            options={options}
            register={register}
            defaultValue={defaultValue}
            button={button}
            icon={icon}
            onClick={onClick}
          />
        ) : null;
      case "checkbox":
        return (
          <Box sx={{ mt: -1 }}>
            <Checkbox
              sx={{ marginLeft: "-11px" }}
              defaultChecked={defaultValue}
              {...register(name)}
              disabled={disabled}
              hidden={hidden}
            />
          </Box>
        );
      case "autocomplete":
        return (
          <Autocomplete
            defaultValue={defaultValue}
            onChange={(e: any, value: string) => setValue(name, value)}
            isOptionEqualToValue={(option: any, value: any) =>
              option.id === value.id
            }
            options={options}
            disabled={disabled}
            getOptionLabel={(option: any) => option.label}
            renderInput={(params) => <TextField {...params} size="small" />}
            hidden={hidden}
          />
        );
      case "editor":
        return (
          <TextEditor
            initialValue={defaultValue}
            control={control}
            name={name}
          />
        );
      case "image":
        return (
          <ImageField
            label={label}
            name={name}
            value={defaultValue}
            setValue={setValue}
            register={register}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      {type !== "checkboxes" && type !== "image" && !hidden && (
        <Typography
          component={"label"}
          htmlFor={name}
          sx={{ mb: "4px", display: "inline-block" }}
        >
          {label}
        </Typography>
      )}
      {!hidden && renderField()}
      <Typography color="error" sx={{ fontSize: 14 }}>
        {errors[name]?.message}
      </Typography>
    </Box>
  );
};

export default FormField;
