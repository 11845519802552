import { ListingByConceptVariables } from "./../models/app";
import { DataStore, SortDirection } from "aws-amplify";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import { setListing, setSelected } from "../store/ducks/kiosk";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { Kiosk } from "../models";
import {
  CreateKioskInput,
  CreateKioskStatusInput,
  CreateTimelineInput,
} from "../models/api";
import {
  CreateVariables,
  KioskBulkTrashVariables,
  GetVariables,
  UpdateVariables,
  Option,
} from "../models/app";
import useKioskStatus from "./useKioskStatus";
import useTimeline from "./useTimeline";
import { TimelineActions } from "../constants/enums";
import { deleteKiosk, updateKiosk } from "../graphql/mutations";
import { listKiosks } from "../graphql/queries";

const useResource = (listingName: string, singleName: string) => {
  const session = useSelector((state: any) => state.app.session);
  const conceptsSelected = useSelector((state: any) => state.concepts.selected);
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const { kioskStatusesCreate } = useKioskStatus(
    "kioskStatuses",
    "kioskStatus"
  );
  const { timelinesCreate } = useTimeline("timelines", "timeline");

  async function fetch(params: ListingByConceptVariables) {
    const { conceptID, searchText, startIndex, limit } = params;

    try {
      // Filter Section
      const filter: any = {
        deleted: { eq: "0" },
        conceptID: { eq: conceptID },
      };

      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }

      const ListData: any = await API.graphql<Kiosk>({
        query: listKiosks,
        variables: { filter, limit, nextToken: null },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const listing = ListData.data.listKiosks.items;

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;

    try {
      const single: Kiosk | undefined = await DataStore.query(Kiosk as any, id);

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateKioskInput = {
        conceptID: conceptsSelected,
        name: data.name,
        screenSaver: data.screenSaver,
        inService: data.inService,
        startTime: data.startTime ? parseInt(data.startTime) : 0,
        closeTime: data.closeTime ? parseInt(data.closeTime) : 0,
        type: data.type,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      const kiosk: Kiosk = await DataStore.save(new Kiosk(createInput as any));

      if (data.inService === true) {
        const createStatusInput: CreateKioskStatusInput = {
          kioskID: kiosk.id,
          upTimeStart: Date.now().toString(),
          deleted: "0",
          createdAt: new Date().toLocaleString(),
          createdByID: session.sub,
          createdByName: session.name,
        };

        await kioskStatusesCreate(createStatusInput);
      }

      // Create timeline
      const createTimelineInput: CreateTimelineInput = {
        actionName: TimelineActions.CREATE_KIOSK,
        oldStatus: "",
        newStatus: kiosk.name,
        userId: session.sub,
        kioskId: kiosk.id,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: session.sub,
        createdByName: session.name,
      };
      await timelinesCreate(createTimelineInput);

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: UpdateVariables) {
    const { id, data } = params;

    try {
      const original = await get({ id });

      await DataStore.save(
        Kiosk.copyOf(original!, (updated) => {
          updated.name = data.name ? data.name : original!.name;
          updated.screenSaver = data.screenSaver
            ? data.screenSaver
            : original!.screenSaver;
          updated.inService =
            data.inService !== undefined ? data.inService : original!.inService;
          updated.startTime = data.startTime ? parseInt(data.startTime) : 0;
          updated.closeTime = data.closeTime ? parseInt(data.closeTime) : 0;
          updated.type = data.type ? data.type : original!.type;
        })
      );

      var createTimelineInput: CreateTimelineInput = {
        actionName: TimelineActions.UPDATE_KIOSK,
        oldStatus: "",
        newStatus: "",
        kioskId: id,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: session.sub,
        createdByName: session.name,
      };

      // Update kiosk status
      if (data.inService === false) {
        // Create timeline
        createTimelineInput.oldStatus = "Kiosk in service";
        createTimelineInput.newStatus = "Kiosk out of service";

        await timelinesCreate(createTimelineInput);
      } else if (data.inService === true) {
        createTimelineInput.oldStatus = "Kiosk out of service";
        createTimelineInput.newStatus = "Kiosk in service";
        await timelinesCreate(createTimelineInput);
      }

      if (data.name && data.name !== original!.name) {
        createTimelineInput.actionName = TimelineActions.UPDATE_KIOSK + " name";
        createTimelineInput.oldStatus = original!.name;
        createTimelineInput.newStatus = data.name;
        await timelinesCreate(createTimelineInput);
      }

      if (data.type && data.type !== original!.type) {
        createTimelineInput.actionName = TimelineActions.UPDATE_KIOSK + " type";
        createTimelineInput.oldStatus = original!.type ? original!.type : "";
        createTimelineInput.newStatus = data.type;
        await timelinesCreate(createTimelineInput);
      }

      if (data.screenSaver && data.screenSaver !== original!.screenSaver) {
        // Get screen saver name
        createTimelineInput.actionName =
          TimelineActions.UPDATE_KIOSK + " screen saver";
        createTimelineInput.oldStatus = "";
        createTimelineInput.newStatus = "";
        await timelinesCreate(createTimelineInput);
      }

      if (data.startTime && parseInt(data.startTime) !== original!.startTime) {
        createTimelineInput.actionName =
          TimelineActions.UPDATE_KIOSK + " start service time";
        createTimelineInput.oldStatus = original!.startTime + "";
        createTimelineInput.newStatus = data.startTime;
        await timelinesCreate(createTimelineInput);
      }

      if (data.closeTime && parseInt(data.closeTime) !== original!.closeTime) {
        createTimelineInput.actionName =
          TimelineActions.UPDATE_KIOSK + " end service time";

        createTimelineInput.oldStatus = original!.closeTime + "";
        createTimelineInput.newStatus = data.closeTime;
        await timelinesCreate(createTimelineInput);
      }

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original: any = await get(params);

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput = {
        id: original.id,
        deleted: "1",
        _version: original._version,
      };

      await API.graphql<Kiosk>({
        query: updateKiosk,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: KioskBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: GetVariables) {
    const { id } = params;

    try {
      await API.graphql<Kiosk>({
        query: deleteKiosk,
        variables: { id: id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: Kiosk[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Type",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const api: any = {};

  api[`${listingName}Model`] = Kiosk as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Kiosk[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));

  return api;
};

export default useResource;
