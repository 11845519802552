import { useSelector } from "react-redux";
import { NOTIFICATION_CHANNELS, Pages } from "../constants/enums";
import { capitalizeSentence } from "../helpers/utils";
import { FormFieldsVariables, Option } from "../models/app";
import useApp from "./useApp";
import useLocalization from "./useLocalization";

const useFormFields = (params: FormFieldsVariables) => {
  const userPermissions = useSelector(
    (state: any) => state.app.userPermissions
  );
  const { strings } = useLocalization();
  const { isStockManager } = useApp();
  const {
    slug,
    model,
    languagesOptions,
    featuresOptions,
    conceptsOptions,
    categoriesOptions,
    menuItemsOptions,
    choiceGroupsOptions,
    screenSaversOptions,
    kiosksOptions,
    adminsOptions,
    adminRolesOptions,
    conceptsSelected,
    videosOptions,
  } = params;

  const loginFields = () => [
    {
      name: "email",
      label: strings.email,
      type: "textField",
      autoFocus: true,
      defaultValue: "",
    },
    {
      name: "password",
      label: strings.password,
      type: "password",
      autoFocus: false,
      defaultValue: "",
    },
  ];

  const registerFields = () => [
    {
      name: "name",
      label: "Full Name",
      type: "textField",
      autoFocus: true,
      defaultValue: "",
    },
    {
      name: "email",
      label: strings.email,
      type: "textField",
      autoFocus: false,
      defaultValue: "",
    },
    {
      name: "password",
      label: strings.password,
      type: "password",
      autoFocus: false,
      defaultValue: "",
    },
  ];

  const adminFields = () => [
    {
      name: "name",
      label: "Full Name",
      type: "textField",
      autoFocus: true,
      defaultValue: "",
    },
    {
      name: "email",
      label: strings.email,
      type: "textField",
      autoFocus: false,
      defaultValue: "",
    },
    {
      name: "password",
      label: strings.password,
      type: "password",
      autoFocus: false,
      defaultValue: "",
    },
    {
      name: "concept",
      label: "Concept",
      type: "select",
      autoFocus: false,
      defaultValue: conceptsSelected,
      options: conceptsOptions,
    },
  ];

  const screenSaverFields = () => {
    let defaultKioskID: string = "-1";
    let defaultVideoName: string = "";

    if (kiosksOptions && kiosksOptions.length > 0) {
      defaultKioskID = kiosksOptions[0].value;
    }

    if (videosOptions && videosOptions.length > 0) {
      defaultVideoName = videosOptions[0].value;
    }

    return [
      {
        name: "kioskID",
        label: "Kiosk ID",
        type: "select",
        autoFocus: true,
        options: kiosksOptions,
        defaultValue: model ? model.kioskID : defaultKioskID,
      },
      {
        name: "name",
        label: "Name",
        type: "textField",
        autoFocus: false,
        defaultValue: model ? capitalizeSentence(model.name) : "",
      },
      {
        name: "video",
        label: "Video Name",
        type: "select",
        autoFocus: false,
        defaultValue: defaultVideoName,
        options: videosOptions,
      },
    ];
  };

  const forgetPasswordFields = () => [
    {
      name: "email",
      label: strings.email,
      type: "textField",
      autoFocus: true,
      defaultValue: "",
    },
  ];
  const resetPasswordFields = () => [
    {
      name: "email",
      label: strings.email,
      type: "textField",
      autoFocus: true,
      defaultValue: "",
    },
    {
      name: "code",
      label: strings.code,
      type: "textField",
      autoFocus: false,
      defaultValue: "",
    },
    {
      name: "newPassword",
      label: strings.password,
      type: "password",
      autoFocus: false,
      defaultValue: "",
    },
  ];

  const accountFields = () => [
    {
      name: "domain",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? capitalizeSentence(model.domain) : "",
    },
    {
      name: "siteTitle",
      label: "Site Title",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? capitalizeSentence(model.siteTitle) : "",
    },
    {
      name: "tagline",
      label: "Tagline",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? capitalizeSentence(model.tagline) : "",
    },
    {
      name: "description",
      label: "Description",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? capitalizeSentence(model.description) : "",
    },
    {
      name: "siteAddress",
      label: "Site Address",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.siteAddress : "",
    },
    {
      name: "defaultLanguage",
      label: "Default Language",
      type: "select",
      autoFocus: false,
      defaultValue: model
        ? model.defaultLanguage
        : languagesOptions
        ? languagesOptions[0].value
        : "-1",
      options: languagesOptions,
    },
  ];

  const callCenterFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
  ];

  const tableFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "label",
      label: "Label",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.label : "",
    },
    {
      name: "width",
      label: "Width",
      type: "number",
      autoFocus: false,
      defaultValue: model ? model.width : "",
    },
    {
      name: "height",
      label: "Height",
      type: "number",
      autoFocus: false,
      defaultValue: model ? model.height : "",
    },
    {
      name: "capacity",
      label: "Max Covers",
      type: "number",
      autoFocus: false,
      defaultValue: model ? model.capacity : "",
    },
  ];

  const userFields = () => [];

  const areaFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "isDefault",
      label: "IS Default",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? model.isDefault : "",
    },
  ];

  const conceptFields = () => [
    {
      name: "name",
      label: "Concept Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
  ];

  const featureFields = () => [
    {
      name: "precedence",
      label: "Precedence",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.precedence : "",
    },
    {
      name: "name",
      label: "Feature Name",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? capitalizeSentence(model.name) : "",
    },
    {
      name: "icon",
      label: "Feature Icon",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.icon : "",
    },
    {
      name: "slug",
      label: "Feature Slug",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.slug : "",
    },
    {
      name: "parent",
      label: "Parent",
      type: "select",
      autoFocus: false,
      defaultValue: model
        ? model.parent
          ? model.parent
          : featuresOptions
          ? featuresOptions[0].value
          : "-1"
        : featuresOptions
        ? featuresOptions[0].value
        : "-1",
      options: featuresOptions,
    },
    {
      name: "private",
      label: "Private",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? model.private : false,
    },
  ];

  const languageFields = () => [
    {
      name: "name",
      label: "Language Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "code",
      label: "Language Code",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.code : "",
    },
  ];

  const socialLinkFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "icon",
      label: "Icon",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.code : "",
    },
    {
      name: "slug",
      label: "URL",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.code : "",
    },
  ];

  const groupFields = (numToUpgradeWatch: any, numToDowngradeWatch: any) => [];

  const notificationsFields = (channelsWatch?: any) => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "channel",
      label: "Channel",
      type: "select",
      autoFocus: false,
      defaultValue: model ? model.channel : NOTIFICATION_CHANNELS[0].value,
      options: NOTIFICATION_CHANNELS,
    },
    {
      name: "from",
      label: "From",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.from : "",
    },
    {
      name: "message",
      label: "Message",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.message : "",
      hidden: channelsWatch === "email",
    },
    {
      name: "enabled",
      label: "Enabled",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? (model.enabled ? model.enabled : false) : false,
    },
    {
      name: "autoSend",
      label: "Auto Send",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? (model.autoSend ? model.autoSend : false) : false,
    },
  ];

  const statusFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "category",
      label: "Category",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.category : "",
    },
  ];

  const flagFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "icon",
      label: "Icon",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.icon : "",
    },
  ];

  const interestFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
  ];

  const groupSettingFields = () => [];

  const planItemFields = () => [];

  const timeSlotFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "friendlyName",
      label: "Friendly Name",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.friendly : "",
    },
  ];

  const categoryFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? capitalizeSentence(model.name) : "",
    },
    {
      name: "precedence",
      label: "Precedence",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.precedence : "",
    },
    {
      name: "symphonyID",
      label: "Symphony ID",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.symphonyID : "",
    },
  ];

  const bannersFields = () => {
    let defaultKioskID: string = "-1";

    if (kiosksOptions && kiosksOptions.length > 0) {
      defaultKioskID = kiosksOptions[0].value;
    }

    return [
      {
        name: "kioskID",
        label: "Kiosk ID",
        type: "select",
        autoFocus: false,
        defaultValue: model
          ? capitalizeSentence(model.kioskID)
          : defaultKioskID,
        options: kiosksOptions,
      },
      {
        name: "title",
        label: "Title",
        type: "textField",
        autoFocus: true,
        defaultValue: model ? model.title : "",
      },
      // {
      //   name: "description",
      //   label: "Description",
      //   type: "textField",
      //   autoFocus: false,
      //   defaultValue: model ? model.description : "",
      // },
    ];
  };

  const ordersFields = () => [
    {
      name: "symphonyID",
      label: "Symphony ID",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.symphonyID : "",
    },
    {
      name: "checkNumber",
      label: "Check Number",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.checkNumber : "0",
    },
    {
      name: "status",
      label: "Status",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.status : "",
    },
    {
      name: "reason",
      label: "Reason",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.reason : "0",
    },
    {
      name: "totalAmount",
      label: "Total Amount",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.totalAmount.toString() : "",
    },
    {
      name: "tenderId",
      label: "Tender ID",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.tenderId.toString() : "",
    },
    {
      name: "paymentType",
      label: "Payment Type",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.paymentType : "",
    },
    {
      name: "orderTypeId",
      label: "Order Type ID",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.orderTypeId.toString() : "",
    },
    {
      name: "orderType",
      label: "Order Type",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.orderType : "",
    },
    {
      name: "employeeId",
      label: "Employee ID",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.employeeId.toString() : "",
    },
    {
      name: "rvcId",
      label: "Revenue Center ID",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.rvcId.toString() : "",
    },
  ];

  const orderItemsFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.name : "",
    },
    {
      name: "symphonyID",
      label: "Symphony ID",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.symphonyID : "0",
    },
    {
      name: "menuItemID",
      label: "Menu Item ID",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.menuItemID : "0",
    },
    {
      name: "price",
      label: "Price",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.price.toString() : "",
    },
    {
      name: "quantity",
      label: "Quantity",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.quantity.toString() : "",
    },
  ];

  const menuItemFields = () => {
    let defaultCategoryID: string = "-1";

    if (categoriesOptions && categoriesOptions.length > 0) {
      defaultCategoryID = categoriesOptions[0].value;
    }

    return [
      {
        name: "name",
        label: "Name",
        type: "textField",
        autoFocus: true,
        defaultValue: model ? model.name : "",
        disabled: isStockManager(userPermissions),
      },
      {
        name: "description",
        label: "Description",
        type: "textField",
        autoFocus: false,
        defaultValue: model ? model.description : "",
        disabled: isStockManager(userPermissions),
      },
      {
        name: "categoryID",
        label: "Category",
        type: "select",
        autoFocus: false,
        defaultValue: model ? model.categoryID : defaultCategoryID,
        options: categoriesOptions,
        disabled: isStockManager(userPermissions),
      },
      {
        name: "symphonyID",
        label: "Symphony ID",
        type: "textField",
        autoFocus: false,
        defaultValue: model ? model.symphonyID : "",
        disabled: isStockManager(userPermissions),
      },
      {
        name: "outOfStock",
        label: "Out of Stock",
        type: "checkbox",
        autoFocus: false,
        defaultValue: model
          ? model.outOfStock
            ? model.outOfStock
            : false
          : false,
      },
      {
        name: "hasLimitedQuantity",
        label: "Has Limited Quantity",
        type: "checkbox",
        autoFocus: false,
        defaultValue: model
          ? model.hasLimitedQuantity
            ? model.hasLimitedQuantity
            : false
          : false,
      },
      {
        name: "quantity",
        label: "Stock Quantity",
        type: "number",
        autoFocus: false,
        defaultValue: model ? model.quantity : 0,
      },
      {
        name: "autoRestock",
        label: "Auto Restock Daily",
        type: "checkbox",
        autoFocus: false,
        defaultValue: model
          ? model.autoRestock
            ? model.autoRestock
            : false
          : false,
      },
      {
        name: "restockQuantity",
        label: "Restock Quantity",
        type: "number",
        autoFocus: false,
        defaultValue: model ? model.restockQuantity : 0,
      },
    ];
  };

  const kiosksFields = () => {
    let defaultScreenSaver: string = "-1";
    let defaultKioskType: string = "-1";

    let kioskTypeOptions: Option[] = [];
    kioskTypeOptions.push({ label: "Kiosk", value: "kiosk" });
    kioskTypeOptions.push({ label: "Bouffe", value: "bouffe" });

    if (screenSaversOptions && screenSaversOptions.length > 0) {
      defaultScreenSaver = screenSaversOptions[0].value;
    }
    if (kioskTypeOptions && kioskTypeOptions.length > 0) {
      defaultKioskType = kioskTypeOptions[0].value;
    }

    return [
      {
        name: "name",
        label: "Name",
        type: "textField",
        autoFocus: true,
        defaultValue: model ? model.name : "",
      },

      {
        name: "type",
        label: "Type",
        type: "select",
        autoFocus: false,
        defaultValue: model ? model.type : defaultKioskType,
        options: kioskTypeOptions,
      },
      {
        name: "screenSaver",
        label: "ScreenSaver",
        type: "select",
        autoFocus: false,
        defaultValue: model ? model : defaultScreenSaver,
        options: screenSaversOptions,
      },
      {
        name: "startTime",
        label: "Start service time",
        type: "number",
        autoFocus: false,
        defaultValue: model ? model.startTime : 0,
      },
      {
        name: "closeTime",
        label: "End service time",
        type: "number",
        autoFocus: false,
        defaultValue: model ? model.closeTime : 0,
      },
      {
        name: "inService",
        label: "In Service?",
        type: "checkbox",
        autoFocus: false,
        defaultValue: model
          ? model.inService
            ? model.inService
            : false
          : false,
      },
    ];
  };

  const userCheckboxes = () => [];

  const accountCheckboxes = () => [
    {
      name: "languages",
      label: "Languages",
      type: "checkboxes",
      autoFocus: false,
      defaultValue: model ? new Set(model.languages) : new Set(),
      options: languagesOptions,
    },
    {
      name: "features",
      label: "Features",
      type: "checkboxes",
      autoFocus: false,
      defaultValue: model ? new Set(model.features) : new Set(),
      options: featuresOptions,
    },
  ];

  const menuItemCheckboxes = () => [
    {
      name: "choiceGroups",
      label: "Choice Groups",
      type: "checkboxes",
      autoFocus: false,
      defaultValue: model ? new Set(model.choiceGroups) : new Set(),
      options: choiceGroupsOptions,
    },
  ];

  const bookingConfirmationFields = () => [
    {
      name: "email",
      type: "textField",
      autoFocus: true,
      defaultValue: "",
    },
  ];

  const serviceFields = () => [
    {
      name: "name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "enabled",
      label: "Enabled",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model
        ? model.enabled !== undefined
          ? model.enabled
          : false
        : true,
    },
  ];

  const choiceFields = () => {
    let defaultChoiceGroupID: string = "-1";

    if (choiceGroupsOptions && choiceGroupsOptions.length > 0) {
      defaultChoiceGroupID = choiceGroupsOptions[0].value;
    }

    return [
      {
        name: "name",
        label: "Name",
        type: "textField",
        autoFocus: true,
        defaultValue: model ? model.name : "",
      },
      {
        name: "price",
        label: "Price",
        type: "textField",
        autoFocus: false,
        defaultValue: model ? model.price.toString() : "",
      },
      {
        name: "choiceGroupID",
        label: "Choice Group",
        type: "select",
        autoFocus: false,
        defaultValue: model ? model.choiceGroupID : defaultChoiceGroupID,
        options: choiceGroupsOptions,
      },
    ];
  };

  const choiceGroupFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "maxNumberOfChoices",
      label: "Max Number Of Choices",
      type: "number",
      autoFocus: false,
      defaultValue: model ? model.maxNumberOfChoices : "",
    },
    {
      name: "minNumberOfChoices",
      label: "Min Number Of Choices",
      type: "number",
      autoFocus: false,
      defaultValue: model ? model.minNumberOfChoices : "",
    },
    {
      name: "precedence",
      label: "Precedence",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.precedence : "",
    },
    {
      name: "required",
      label: "Required",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? model.required : "",
    },
  ];

  const paymentMethodFields = () => [
    {
      name: "name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "enabled",
      label: "Enabled",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model
        ? model.enabled !== undefined
          ? model.enabled
          : false
        : true,
    },
  ];

  const timelineFields = () => [
    {
      name: "action",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.action : "",
    },
    {
      name: "oldStatus",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.oldStatus : "",
    },
    {
      name: "newStatus",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.newStatus : "",
    },
  ];

  const adminGroupsFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "description",
      label: "Description",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.description : "",
    },
  ];

  const adminRoleFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "description",
      label: "Description",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.description : "",
    },
  ];

  const videosFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
  ];

  const getFormFields = (
    numToUpgradeWatch?: any,
    numToDowngradeWatch?: any,
    channelsWatch?: any
  ) => {
    switch (slug) {
      case Pages.LOGIN:
        return loginFields();
      case Pages.REGISTER:
        return registerFields();
      case Pages.ADMINS:
        return adminFields();
      case Pages.FORGOT_PASSWORD:
        return forgetPasswordFields();
      case Pages.RESET_PASSWORD:
        return resetPasswordFields();
      case Pages.ACCOUNTS:
        return accountFields();
      case Pages.AREAS:
        return areaFields();
      case Pages.CONCEPTS:
        return conceptFields();
      case Pages.FEATURES:
        return featureFields();
      case Pages.LANGUAGES:
        return languageFields();
      case Pages.SOCIAL_LINKS:
        return socialLinkFields();
      case Pages.GROUPS:
        return groupFields(numToUpgradeWatch, numToDowngradeWatch);
      case Pages.RESERVATION_STATUS:
        return statusFields();
      case Pages.FLAGS:
        return flagFields();
      case Pages.INTERESTS:
        return interestFields();
      case Pages.GROUP_SETTINGS:
        return groupSettingFields();
      case Pages.PLAN_ITEMS:
        return planItemFields();
      case Pages.GUESTS:
        return userFields();
      case Pages.CALL_CENTER_SETTINGS:
        return callCenterFields();
      case Pages.TABLES:
        return tableFields();
      case Pages.TIME_SLOTS:
        return timeSlotFields();
      case Pages.BOOKING_CONFIRMATION:
        return bookingConfirmationFields();
      case Pages.NOTIFICATIONS:
        return notificationsFields(channelsWatch);
      case Pages.CATEGORIES:
        return categoryFields();
      case Pages.MENU_ITEMS:
        return menuItemFields();
      case Pages.SCREEN_SAVER:
        return screenSaverFields();
      case Pages.BANNERS:
        return bannersFields();
      case Pages.ORDERS:
        return ordersFields();
      case Pages.ORDER_ITEMS:
        return orderItemsFields();
      case Pages.KIOSKS:
        return kiosksFields();
      case Pages.CHOICES:
        return choiceFields();
      case Pages.CHOICE_GROUPS:
        return choiceGroupFields();
      case Pages.SERVICES:
        return serviceFields();
      case Pages.PAYMENT_METHODS:
        return paymentMethodFields();
      case Pages.TIMELINES:
        return timelineFields();
      case Pages.ADMIN_GROUPS:
        return adminGroupsFields();
      case Pages.ADMIN_ROLES:
        return adminRoleFields();
      case Pages.VIDEOS:
        return videosFields();
      default:
        return [];
    }
  };

  const adminGroupsCheckboxes = () => [
    {
      name: "roles",
      label: "Admin Roles",
      type: "checkboxes",
      autoFocus: false,
      defaultValue: model ? new Set(model.roles) : new Set(),
      options: adminRolesOptions,
    },
    {
      name: "users",
      label: "Admin Users",
      type: "checkboxes",
      autoFocus: false,
      defaultValue: model ? new Set(model.users) : new Set(),
      options: adminsOptions,
    },
  ];

  const choiceGroupsCheckboxes = () => [
    {
      name: "choices",
      label: "Choices",
      type: "checkboxes",
      autoFocus: false,
      defaultValue: model ? new Set(model.choices) : new Set(),
      options: menuItemsOptions,
    },
  ];

  const getCheckboxesFields = () => {
    switch (slug) {
      case Pages.ACCOUNTS:
        return accountCheckboxes();
      case Pages.MENU_ITEMS:
        return menuItemCheckboxes();
      case Pages.GUESTS:
        return userCheckboxes();
      case Pages.ADMIN_GROUPS:
        return adminGroupsCheckboxes();
      case Pages.CHOICE_GROUPS:
        return choiceGroupsCheckboxes();
      default:
        return [];
    }
  };

  const screenSaverImages = () => [
    {
      name: "image",
      label: "Image",
      type: "image",
      autoFocus: false,
      defaultValue: model ? model.image : "",
    },
  ];

  const menuItemImages = () => [
    {
      name: "image",
      label: "Image",
      type: "image",
      autoFocus: false,
      defaultValue: model ? model.image : "",
    },
  ];

  const bannerImages = () => [
    {
      name: "image",
      label: "Image",
      type: "image",
      autoFocus: false,
      defaultValue: model ? model.image : "",
    },
  ];

  const categoryImages = () => [
    {
      name: "image",
      label: "Image",
      type: "image",
      autoFocus: false,
      defaultValue: model ? model.image : "",
    },
  ];

  const serviceImages = () => [
    {
      name: "image",
      label: "Image",
      type: "image",
      autoFocus: false,
      defaultValue: model ? model.image : "",
    },
  ];

  const paymentMethodImages = () => [
    {
      name: "image",
      label: "Image",
      type: "image",
      autoFocus: false,
      defaultValue: model ? model.image : "",
    },
  ];

  const getImageFields = () => {
    switch (slug) {
      case Pages.SCREEN_SAVER:
        return screenSaverImages();
      case Pages.MENU_ITEMS:
        return menuItemImages();
      case Pages.CATEGORIES:
        return categoryImages();
      case Pages.BANNERS:
        return bannerImages();
      case Pages.SERVICES:
        return serviceImages();
      case Pages.PAYMENT_METHODS:
        return paymentMethodImages();
      default:
        return [];
    }
  };

  return {
    getFormFields,
    getCheckboxesFields,
    getImageFields,
  };
};

export default useFormFields;
