import { Suspense } from "react";
import Backdrop from "@mui/material/Backdrop";

import ContentLoader from "../ContentLoader";
import useApp from "../../../hooks/useApp";
import Box from "@mui/material/Box";
import ChoiceModal from "../ChoiceModal";
import { useSelector } from "react-redux";

const SecondaryModal: React.FC = () => {
  const secondaryModalOpen = useSelector(
    (state: any) => state.app.secondaryModalOpen
  );
  const secondaryModalContent = useSelector(
    (state: any) => state.app.secondaryModalContent
  );
  const secondaryModalContentId = useSelector(
    (state: any) => state.app.secondaryModalContentId
  );
  const { hideSecondaryPopup } = useApp();

  const renderModalContent = () => {
    if (typeof secondaryModalContent === "object") {
      const { slug } = secondaryModalContent;

      if (secondaryModalContentId === "ChoiceModal")
        return (
          <ChoiceModal
            choiceGroupId={slug}
            choiceId={secondaryModalContent.resourceId}
          />
        );
    }

    return secondaryModalContent;
  };

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 3,
        alignItems: "flex-start",
      }}
      open={secondaryModalOpen}
    >
      <Box
        sx={{
          position: "absolute",
          inset: 0,
          zIndex: 0,
          cursor: "pointer",
        }}
        onClick={hideSecondaryPopup}
      />
      <Suspense fallback={<ContentLoader />}>{renderModalContent()}</Suspense>
    </Backdrop>
  );
};

export default SecondaryModal;
