// actions
const SET = "areas/SET";
const SET_SEARCH_TEXT = "areas/SET_SEARCH_TEXT";
const SET_SELECTED = "areas/SET_SELECTED";
const SET_DAYS = "areas/SET_DAYS";
const SET_TIME_SLOTS = "areas/SET_TIME_SLOTS";

const DEFAULT_STATE = {
  listing: [],
  searchText: "",
  selected: "",
  days: new Set(),
  timeSlots: new Set(),
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    case SET_DAYS:
      return Object.assign({}, state, { days: action.days });
    case SET_TIME_SLOTS:
      return Object.assign({}, state, { timeSlots: action.timeSlots });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelected = (id: any) => ({ id, type: SET_SELECTED });
export const setDays = (days: any) => ({ days, type: SET_DAYS });
export const setTimeSlots = (timeSlots: any) => ({
  timeSlots,
  type: SET_TIME_SLOTS,
});
export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});
