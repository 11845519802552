import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";

interface Props {
  state: any;
  handleChange: any;
  months: number;
  setLimit: boolean;
}

const DateRange: React.FC<Props> = ({ state, handleChange, months, setLimit }) => {

  if(setLimit){
    return (
      <DateRangePicker
        minDate={new Date()}
        staticRanges={[]}
        inputRanges={[]}
        onChange={handleChange}
        moveRangeOnFirstSelection={false}
        months={months}
        ranges={state}
        direction="horizontal"
      />
    );
  }else{
    return (
      <DateRangePicker
        onChange={handleChange}
        moveRangeOnFirstSelection={false}
        months={months}
        ranges={state}
        direction="horizontal"
      />
    );
  }

};

export default DateRange;
