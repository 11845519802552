// actions
const SET = "bookings/SET";
const SET_SLOTS = "bookings/SET_SLOTS";
const SET_SLOTS_PREV = "bookings/SET_SLOTS_PREV";
const SET_SLOTS_NEXT = "bookings/SET_SLOTS_NEXT";
const SET_STATS = "bookings/SET_STATS";
const SET_CHANNELS = "bookings/SET_CHANNELS";
const SET_RUSH_DAYS = "bookings/SET_RUSH_DAYS";
const SET_SEARCH_TEXT = "bookings/SET_SEARCH_TEXT";
const SET_SELECTED = "bookings/SET_SELECTED";
const SET_DATE = "bookings/SET_DATE";
const SET_TIMES = "bookings/SET_TIMES";
const SET_FLAGS = "bookings/SET_FLAGS";
const SET_STATUS = "bookings/SET_STATUS";
const SET_TABLES = "bookings/SET_TABLES";
const SET_COMMENTS = "bookings/SET_COMMENTS";
const SET_MAIN_GUEST = "bookings/SET_MAIN_GUEST";
const SET_GUESTS_COUNT = "bookings/SET_GUESTS_COUNT";

const DEFAULT_STATE = {
  listing: [],
  listingSlots: [],
  listingSlotsPrev: [],
  listingSlotsNext: [],
  listingStats: [],
  listingChannels: [],
  listingRushDays: [],
  searchText: "",
  selected: null,
  date: new Date(),
  guestsCount: 1,
  mainGuest: "",
  status: "",
  times: new Set(),
  comments: new Set(),
  tables: new Set(),
  flags: new Set(),
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SLOTS:
      return Object.assign({}, state, { listingSlots: action.listing });
    case SET_SLOTS_PREV:
      return Object.assign({}, state, { listingSlotsPrev: action.listing });
    case SET_SLOTS_NEXT:
      return Object.assign({}, state, { listingSlotsNext: action.listing });
    case SET_STATS:
      return Object.assign({}, state, { listingStats: action.listing });
    case SET_CHANNELS:
      return Object.assign({}, state, { listingChannels: action.listing });
    case SET_RUSH_DAYS:
      return Object.assign({}, state, { listingRushDays: action.listing });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.model });
    case SET_DATE:
      return Object.assign({}, state, { date: action.date });
    case SET_TIMES:
      return Object.assign({}, state, { times: action.times });
    case SET_MAIN_GUEST:
      return Object.assign({}, state, { mainGuest: action.id });
    case SET_STATUS:
      return Object.assign({}, state, { status: action.status });
    case SET_TABLES:
      return Object.assign({}, state, { tables: action.tables });
    case SET_COMMENTS:
      return Object.assign({}, state, { comments: action.comments });
    case SET_FLAGS:
      return Object.assign({}, state, { flags: action.flags });
    case SET_GUESTS_COUNT:
      return Object.assign({}, state, { guestsCount: action.count });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setListingSlots = (listing: any[]) => ({
  listing,
  type: SET_SLOTS,
});
export const setListingSlotsPrev = (listing: any[]) => ({
  listing,
  type: SET_SLOTS_PREV,
});
export const setListingSlotsNext = (listing: any[]) => ({
  listing,
  type: SET_SLOTS_NEXT,
});
export const setListingStats = (listing: any[]) => ({
  listing,
  type: SET_STATS,
});
export const setListingChannels = (listing: any[]) => ({
  listing,
  type: SET_CHANNELS,
});
export const setListingRushDays = (listing: any[]) => ({
  listing,
  type: SET_RUSH_DAYS,
});
export const setSelected = (model: any) => ({ model, type: SET_SELECTED });
export const setDate = (date: any) => ({ date, type: SET_DATE });
export const setMainGuest = (id: string) => ({ id, type: SET_MAIN_GUEST });
export const setStatus = (status: string) => ({ status, type: SET_STATUS });
export const setTimes = (times: any) => ({ times, type: SET_TIMES });
export const setTables = (tables: any) => ({ tables, type: SET_TABLES });
export const setComments = (comments: any) => ({
  comments,
  type: SET_COMMENTS,
});
export const setFlags = (flags: any) => ({
  flags,
  type: SET_FLAGS,
});
export const setGuestsCount = (count: number) => ({
  count,
  type: SET_GUESTS_COUNT,
});
export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});
