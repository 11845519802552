import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { setListing } from "../store/ducks/video";
import { Video } from "../models";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import {
  GetVariables,
  CreateVariables,
  VideoBulkTrashVariables,
  UpdateVariables,
  Option,
  ListingByAccountVariables,
} from "../models/app";
import { CreateVideoInput } from "../models/api";

const useResource = (listingName: string, singleName: string) => {
  const accountsSelected = useSelector((state: any) => state.accounts.selected);
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetch(params: ListingByAccountVariables) {
    const { accountID, searchText, startIndex, limit } = params;

    try {
      const listing = await DataStore.query(
        Video as any,
        (model: any) => {
          model.deleted("eq", "0").accountID("eq", accountID);

          if (searchText.length > 0)
            model.name("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;

    try {
      const single: Video | undefined = await DataStore.query(Video as any, id);

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateVideoInput = {
        accountID: accountsSelected.id,
        name: data.name,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      await DataStore.save(new Video(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: UpdateVariables) {
    const { id, data } = params;

    try {
      const original = await get({ id });

      await DataStore.save(
        Video.copyOf(original!, (updated) => {
          updated.name = data.name ? data.name : original!.name;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        Video.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: VideoBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: GetVariables) {
    const { id } = params;

    try {
      await DataStore.delete(id as any);

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: Video[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.name });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const api: any = {};

  api[`${listingName}Model`] = Video as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Video[]) =>
    dispatch(setListing(listing));

  return api;
};

export default useResource;
