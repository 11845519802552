import { UserConcepts } from "../../models";
import { UserPermissions } from "../../models/app";
import { Directions, Languages } from "./../../constants/enums";

// actions
const SET = "app/SET";
const CLEAR = "app/CLEAR";
const SET_DIRECTION = "app/SET_DIRECTION";
const SET_LANGUAGE = "app/SET_LANGUAGE";
const OPEN_MODAL = "app/OPEN_MODAL";
const CLOSE_MODAL = "app/CLOSE_SNACKBAR";
const OPEN_SECONDARY_MODAL = "app/OPEN_SECONDARY_MODAL";
const CLOSE_SECONDARY_MODAL = "app/CLOSE_SECONDARY_SNACKBAR";
const OPEN_SECONDARY_POPUP = "app/OPEN_SECONDARY_POPUP";
const OPEN_DELETE_POPUP = "app/OPEN_DELETE_POPUP";
const OPEN_SNACKBAR = "app/OPEN_SNACKBAR";
const CLOSE_SNACKBAR = "app/CLOSE_MODAL";
const OPEN_DIALOG = "app/OPEN_DIALOG";
const CLOSE_DIALOG = "app/CLOSE_DIALOG";
const SET_SELECTED_USER = "app/SET_SELECTED_USER";
const SET_DATE_RANGE = "app/SET_DATE_RANGE";
const SET_PAGINATE_STATE = "app/SET_PAGINATE_STATE";
const SET_USER_PERMISSIONS = "app/SET_USER_PERMISSIONS";
const SET_USER_CONCEPTS = "app/SET_USER_CONCEPTS";

const DEFAULT_STATE = {
  direction: Directions.LTR,
  language: Languages.ENGLISH,
  modalOpen: false,
  modalContent: null,
  modalContentId: null,
  secondaryModalOpen: false,
  secondaryModalContent: null,
  secondaryModalContentId: null,
  secondaryPopupOpen: false,
  deletePopupOpen: false,
  snackBarOpen: false,
  snackBarSeverity: "info",
  snackBarContent: null,
  snackBarPersist: false,
  session: null,
  dialogOpen: false,
  selectedUser: null,
  dateRange: [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ],
  paginateState: {
    startIndex: 0,
    limit: 10,
  },
  concepts: null,
};

// reducer
const appReducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET_DIRECTION:
      return Object.assign({}, state, { direction: action.direction });
    case SET_LANGUAGE:
      return Object.assign({}, state, { language: action.language });
    case OPEN_MODAL:
      return Object.assign({}, state, {
        modalOpen: true,
        modalContent: action.modalContent,
        modalContentId: action.modalContentId ? action.modalContentId : null,
      });
    case OPEN_SECONDARY_MODAL:
      return Object.assign({}, state, {
        secondaryModalOpen: true,
        secondaryModalContent: action.secondaryModalContent,
        secondaryModalContentId: action.secondaryModalContentId
          ? action.secondaryModalContentId
          : null,
      });
    case SET_PAGINATE_STATE:
      return Object.assign({}, state, {
        paginateState: action.paginateState,
      });
    case CLOSE_SECONDARY_MODAL:
      return Object.assign({}, state, {
        secondaryModalOpen: false,
        secondaryModalContent: null,
        secondaryModalContentId: null,
      });
    case CLOSE_MODAL:
      return Object.assign({}, state, {
        modalOpen: false,
        modalContent: null,
        modalContentId: null,
      });
    case OPEN_SNACKBAR:
      return Object.assign({}, state, {
        snackBarOpen: true,
        snackBarContent: action.snackBarContent,
        snackBarSeverity: action.snackBarSeverity,
        snackBarPersist: action.persist ? action.persist : false,
      });
    case CLOSE_SNACKBAR:
      return Object.assign({}, state, {
        snackBarOpen: false,
        snackBarContent: null,
        snackBarSeverity: "info",
      });
    case OPEN_DELETE_POPUP:
      return Object.assign({}, state, {
        deletePopupOpen: true,
        toDelete: action.id,
      });
    case OPEN_SECONDARY_POPUP:
      return Object.assign({}, state, {
        secondaryPopupOpen: true,
        toDelete: action.id,
      });
    case SET:
      return Object.assign({}, state, { session: action.session });
    case SET_SELECTED_USER:
      return Object.assign({}, state, { selectedUser: action.selectedUser });
    case CLEAR:
      return Object.assign({}, state, { session: null });
    case OPEN_DIALOG:
      return Object.assign({}, state, {
        dialogOpen: true,
        // dialogContent: action.dialogContent,
      });
    case CLOSE_DIALOG:
      return Object.assign({}, state, {
        dialogOpen: false,
        // dialogContent: null,
      });
    case SET_DATE_RANGE:
      return Object.assign({}, state, { dateRange: action.dateRange });
    case SET_USER_PERMISSIONS:
      return Object.assign({}, state, { userPermissions: action.permissions });
    case SET_USER_CONCEPTS:
      return Object.assign({}, state, { concepts: action.payload });
    default:
      return state;
  }
};

export default appReducer;

// action creators
export const setDirection = (direction: string) => ({
  direction,
  type: SET_DIRECTION,
});

export const setLanguage = (language: string) => ({
  language,
  type: SET_DIRECTION,
});

export const setPaginateState = (paginateState: string) => ({
  paginateState,
  type: SET_PAGINATE_STATE,
});

export const openModal = (modalContent: any, modalContentId?: any) => ({
  modalContent,
  modalContentId,
  type: OPEN_MODAL,
});

export const openSecondaryModal = (
  secondaryModalContent: any,
  secondaryModalContentId?: any
) => ({
  secondaryModalContent,
  secondaryModalContentId,
  type: OPEN_SECONDARY_MODAL,
});

export const closeModal = () => ({ modalContent: null, type: CLOSE_MODAL });
export const closeSecondaryModal = () => ({
  secondaryModalContent: null,
  type: CLOSE_SECONDARY_MODAL,
});

export const openDialog = () => ({
  type: OPEN_DIALOG,
});

export const closeDialog = () => ({
  type: CLOSE_DIALOG,
});

export const openSnackBar = (
  snackBarContent: string,
  snackBarSeverity: string,
  persist?: boolean
) => ({ snackBarContent, snackBarSeverity, persist, type: OPEN_SNACKBAR });

export const closeSnackBar = () => ({
  snackBarContent: null,
  type: CLOSE_SNACKBAR,
});

export const openDeletePopup = (id: string) => ({
  id,
  type: OPEN_DELETE_POPUP,
});

export const openSecondaryPopup = (id: string) => ({
  id,
  type: OPEN_SECONDARY_POPUP,
});

export const setSelectedUser = (selectedUser: any) => ({
  selectedUser,
  type: SET_SELECTED_USER,
});

export const setSession = (session: any) => ({ session, type: SET });

export const clearSession = () => ({ type: CLEAR });
export const setDateRange = (dateRange: any) => ({
  dateRange,
  type: SET_DATE_RANGE,
});

export const setUserPermissions = (permissions: UserPermissions) => ({
  permissions,
  type: SET_USER_PERMISSIONS,
});

export const setUserConcepts = (payload: UserConcepts | null) => ({
  payload,
  type: SET_USER_CONCEPTS,
});
