import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";

import ImagePicker from "../ImagePicker";
import { openModal, closeModal } from "../../../store/ducks/app";

import { commonWhite } from "../../../constants/colors";
import ImageContainer from "../ImageContainer";
import useMedia from "../../../hooks/useMedia";
import React from "react";
import useApp from "../../../hooks/useApp";

interface Props {
  label: string;
  attachment: any;
  changeAttachment: any;
}

const SetImage: React.FC<Props> = ({ label, attachment, changeAttachment }) => {
  const dispatch = useDispatch();
  const userPermissions = useSelector(
    (state: any) => state.app.userPermissions
  );
  const { isStockManager } = useApp();
  const { mediaLibraryChangeSelected } = useMedia("mediaLibrary", "media");

  const handleEditImage = () => {
    if (!isStockManager(userPermissions)) {
      dispatch(
        openModal(
          <ImagePicker
            title={label}
            attachment={attachment}
            changeAttachment={(attachment: any) => changeAttachment(attachment)}
            closeModal={() => {
              mediaLibraryChangeSelected(new Set());
              dispatch(closeModal());
            }}
          />
        )
      );
    }
  };

  return (
    <>
      {attachment && (
        <ImageContainer
          src={attachment.fileUrl}
          thumb={attachment.fileUrl}
          alt={attachment.alternativeText}
        />
      )}
      <Button
        size="small"
        fullWidth
        disableRipple
        disableFocusRipple
        disableTouchRipple
        sx={{
          textDecoration: !isStockManager(userPermissions)
            ? "underline"
            : "none",
          textTransform: "capitalize",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          backgroundColor: (theme) => commonWhite(theme),
          borderTop: "none",
          p: 1,
          cursor: !isStockManager(userPermissions) ? "pointer" : "initial",
          "&:hover": {
            background: "transparent",
            textDecoration: !isStockManager(userPermissions)
              ? "underline"
              : "none",
          },
        }}
        onClick={handleEditImage}
      >
        {label}
      </Button>
    </>
  );
};

export default SetImage;
