import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch } from "react-redux";
import { setListing, setSelected } from "../store/ducks/language";
import { Language } from "../models";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import {
  GetVariables,
  ListingVariables,
  CreateVariables,
  LanguageBulkTrashVariables,
  UpdateVariables,
  Option,
} from "../models/app";
import { CreateLanguageInput } from "../models/api";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetch(params: ListingVariables) {
    const { searchText, startIndex, limit } = params;

    try {
      const listing = await DataStore.query(
        Language as any,
        (model: any) => {
          model.deleted("eq", "0");

          if (searchText.length > 0)
            model.name("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;

    try {
      const single: Language | undefined = await DataStore.query(
        Language as any,
        id
      );

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateLanguageInput = {
        name: data.name,
        code: data.code,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      await DataStore.save(new Language(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: UpdateVariables) {
    const { id, data } = params;

    try {
      const original = await get({ id });

      await DataStore.save(
        Language.copyOf(original!, (updated) => {
          updated.name = data.name ? data.name : original!.name;
          updated.code = data.code ? data.code : original!.code;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        Language.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: LanguageBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: GetVariables) {
    const { id } = params;

    try {
      await DataStore.delete(id as any);

      // dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: Language[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const api: any = {};

  api[`${listingName}Model`] = Language as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Language[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));

  return api;
};

export default useResource;
