import { extractSelectedCheckboxes, getDomainName } from "./../helpers/utils";
import { API, DataStore, Predicates, SortDirection } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useDispatch } from "react-redux";
import { listAccounts } from "../graphql/queries";
import { setListing, setSelected } from "../store/ducks/account";
import { Account } from "../models";
import { CreateAccountInput } from "../models/api";
import {
  HeadCell,
  Option,
  GetVariables,
  UpdateVariables,
  AccountBulkTrashVariables,
  CreateVariables,
  AccountListingVariables,
} from "../models/app";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();

  async function fetch(params: AccountListingVariables) {
    const { searchText, startIndex, limit, domain } = params;
    // console.log("searchText:", searchText);

    try {
      const listing = await DataStore.query(
        Account as any,
        (model: any) => {
          model.deleted("eq", "0");

          if (domain) model.domain("eq", domain);

          if (searchText.length > 0) {
            model.or((model: any) =>
              model
                .domain("contains", searchText.toLowerCase())
                .siteTitle("contains", searchText.toLowerCase())
                .siteAddress("contains", searchText.toLowerCase())
            );
          }

          return model;
        },
        {
          page: startIndex,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function byDomainOffline() {
    try {
      const listing = await DataStore.query(Account as any, Predicates.ALL, {
        page: 0,
        limit: 100,
        sort: (s) => s.createdAt(SortDirection.DESCENDING),
      });

      const account = listing.find(
        (acc: any) => acc.domain === getDomainName()
      );

      if (account) {
        dispatch(setSelected(account));

        return account;
      }
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function byDomainOnline(isAuth: boolean) {
    try {
      const listing: any = await API.graphql({
        query: listAccounts,
        variables: {
          limit: 1000,
        },
        authMode: isAuth
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      if (listing.data.listAccounts.items.length > 0) {
        const account = listing.data.listAccounts.items.find(
          (acc: any) => acc.domain === getDomainName()
        );

        if (account) {
          dispatch(setSelected(account));

          return account;
        }
      }
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;
    try {
      const single: Account | undefined = await DataStore.query(
        Account as any,
        id
      );

      return single;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;
    const languages: string[] = extractSelectedCheckboxes("languages_", data);
    const features: string[] = extractSelectedCheckboxes("features_", data);

    try {
      const createInput: CreateAccountInput = {
        domain: data.domain.toLowerCase(),
        siteTitle: data.siteTitle.toLowerCase(),
        tagline: data.tagline.toLowerCase(),
        description: data.description.toLowerCase(),
        siteAddress: data.siteAddress,
        defaultLanguage: data.defaultLanguage,
        status: "active",
        languages: languages,
        features: features,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      /**
       * @toDO: Add account social media links
       */

      await DataStore.save(new Account(createInput as any));

      return `New ${singleName} has been created successfully`;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function update(params: UpdateVariables) {
    const { id, data } = params;
    const languages: string[] = extractSelectedCheckboxes("languages_", data);
    const features: string[] = extractSelectedCheckboxes("features_", data);

    try {
      const original = await get({ id });

      await DataStore.save(
        Account.copyOf(original!, (updated) => {
          updated.domain = data.domain
            ? data.domain.toLowerCase()
            : original!.domain;
          updated.siteTitle = data.siteTitle
            ? data.siteTitle.toLowerCase()
            : original!.siteTitle;
          updated.tagline = data.tagline
            ? data.tagline.toLowerCase()
            : original!.tagline;
          updated.description = data.description
            ? data.description.toLowerCase()
            : original!.description;
          updated.siteAddress = data.siteAddress
            ? data.siteAddress
            : original!.siteAddress;
          updated.defaultLanguage = data.defaultLanguage
            ? data.defaultLanguage
            : original!.defaultLanguage;
          updated.languages = languages;
          updated.features = features;
        })
      );

      return `${singleName} has been updated successfully`;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        Account.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      return `${singleName} has been moved to trash successfully`;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function bulkTrash(params: AccountBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: string) => {
      try {
        await trash({ id });
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    return `${ids.size} ${listingName} items has been moved to trash`;
  }

  async function remove(params: GetVariables) {
    const { id } = params;

    try {
      await DataStore.delete(id as any);

      // dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      return `${singleName} has been deleted successfully`;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function exportAll(params: AccountListingVariables) {
    const data = await fetch(params);

    let exportedData = [];

    for (let entry of data!) {
      let row: any = { ...entry };
      exportedData.push(row);
    }

    return exportedData;
  }

  function options(listing: Account[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.siteTitle, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "siteTitle",
      numeric: false,
      disablePadding: false,
      label: "Site Title",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["siteTitle"];

  const api: any = {};

  api[`${listingName}Model`] = Account as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchByDomain`] = byDomainOffline;
  api[`${listingName}FetchByDomainOnline`] = byDomainOnline;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Account[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}Export`] = exportAll;

  return api;
};

export default useResource;
