import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";

import Image from "../Image";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";

interface Props {
  src: string;
  thumb: string;
  alt: string;
  withBorder?: boolean;
  width?: number | null;
  height?: number | null;
}

const ImageContainer: React.FC<Props> = ({
  src,
  thumb,
  alt,
  withBorder,
  width,
  height,
}) => {
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [thumbnailWidth, setThumbnailWidth] = useState<number>(0);

  useEffect(() => {
    const thumbnailBox = document.getElementById("thumbnail-box");

    setThumbnailWidth(thumbnailBox!.clientWidth);
  }, []);

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }]: any, observerElement: any) => {
      if (isIntersecting) {
        setIsVisible(true);
        observerElement.unobserve(ref.current);
      }
    },
  });

  let aspectRatio = (thumbnailWidth / thumbnailWidth) * 100;

  if (width && height) aspectRatio = (height / width) * 100;

  return (
    <Box
      id="thumbnail-box"
      ref={ref}
      sx={{
        position: "relative",
        overflow: "hidden",
        display: "block",
        border: "1px solid",
        borderColor: withBorder ? "divider" : "transparent",
        paddingBottom: `${aspectRatio}%`,
        img: { height: "auto" },
      }}
    >
      {isVisible && <Image src={src} thumb={thumb} alt={alt} />}
    </Box>
  );
};

export default ImageContainer;
