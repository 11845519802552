import { CategoryListingVariables } from "./../models/app";
import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { setListing, setSelected } from "../store/ducks/menuItem";
import { ChoiceGroup, MenuItem, Price } from "../models";
import { HeadCell } from "../models/dataTable";
import { CreateMenuItemInput, CreateTimelineInput } from "../models/api";
import useApp from "./useApp";
import useTimeline from "./useTimeline";
import { TimelineActions } from "../constants/enums";
import {
  CreateVariables,
  MenuItemBulkTrashVariables,
  GetVariables,
  UpdateVariables,
  Option,
} from "../models/app";
import usePrice from "./usePrice";
import useChoiceGroup from "./useChoiceGroup";
import { extractSelectedCheckboxes } from "../helpers/utils";

const useResource = (listingName: string, singleName: string) => {
  const session = useSelector((state: any) => state.app.session);
  const conceptsSelected = useSelector((state: any) => state.concepts.selected);
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const { pricesGet, pricesTrash } = usePrice("prices", "price");
  const { choiceGroupsGet, choiceGroupsTrash } = useChoiceGroup(
    "choiceGroups",
    "choiceGroup"
  );
  const { timelinesCreate } = useTimeline("timelines", "timeline");

  async function fetch(params: CategoryListingVariables) {
    const { conceptID, searchText, startIndex, limit, categoryID } = params;

    try {
      const listing = await DataStore.query(
        MenuItem as any,
        (model: any) => {
          model.deleted("eq", "0").conceptID("eq", conceptID);

          if (categoryID) model.categoryID("eq", categoryID);

          if (searchText.length > 0)
            model.name("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;

    try {
      const single: MenuItem | undefined = await DataStore.query(
        MenuItem as any,
        id
      );

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function getPrices(resourceId: string) {
    try {
      const prices: Price[] = [];
      const single: MenuItem | undefined = await DataStore.query(
        MenuItem as any,
        resourceId
      );

      if (!single) return prices;

      if (single.prices && single.prices.length === 0) return prices;

      if (single.prices && single.prices.length > 0) {
        for (let priceID of single.prices) {
          let price = await pricesGet({ id: priceID });
          if (price && price.deleted === "0") prices.push(price);
        }

        return prices;
      }
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function getChoiceGroups(resourceId: string) {
    try {
      const choiceGroups: ChoiceGroup[] = [];

      const single: MenuItem | undefined = await DataStore.query(
        MenuItem as any,
        resourceId
      );
      if (!single) return choiceGroups;

      if (single.choiceGroups && single.choiceGroups.length === 0)
        return choiceGroups;

      if (single.choiceGroups && single.choiceGroups.length > 0) {
        for (let choiceGroupID of single.choiceGroups) {
          let choiceGroup = await choiceGroupsGet({ id: choiceGroupID });
          if (choiceGroup && choiceGroup.deleted === "0")
            choiceGroups.push(choiceGroup);
        }

        return choiceGroups;
      }
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function trashPrice(resourceId: string, priceId: string) {
    try {
      const single: MenuItem | undefined = await DataStore.query(
        MenuItem as any,
        resourceId
      );

      const prices: (string | null)[] =
        single && single.prices ? [...single.prices] : [];

      let price = prices.indexOf(priceId);

      if (price > -1) prices.splice(price, 1);

      await update({ id: resourceId, data: { prices } });
      await pricesTrash({ id: priceId });
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function trashChoiceGroup(resourceId: string, choiceGroupId: string) {
    try {
      const single: MenuItem | undefined = await DataStore.query(
        MenuItem as any,
        resourceId
      );

      let choiceGroups: any[] =
        single && single.choiceGroups ? [...single.choiceGroups] : [];

      let choiceGroup = choiceGroups.indexOf(choiceGroupId);

      if (choiceGroup > -1) choiceGroups.splice(choiceGroup, 1);

      await update({ id: resourceId, data: { choiceGroups } });
      await choiceGroupsTrash({ id: choiceGroupId });
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.name) {
      showError("Name is required");
      return;
    }

    if (!data.categoryID) {
      showError("Category is required");
      return;
    }

    try {
      const createInput: CreateMenuItemInput = {
        conceptID: conceptsSelected,
        name: data.name.toLowerCase(),
        description: data.description ? data.description : "",
        image: data.image ? (data.image.id ? data.image.id : data.image) : "",
        categoryID: data.categoryID,
        outOfStock: data.outOfStock ? data.outOfStock : true,
        enabled: data.enabled ? data.enabled : true,
        symphonyID: data.symphonyID ? data.symphonyID : "0",
        prices: data.prices ? data.prices : [],
        choiceGroups: data.choiceGroups ? data.choiceGroups : [],
        hasLimitedQuantity: data.hasLimitedQuantity
          ? data.hasLimitedQuantity
          : false,
        quantity: data.quantity ? parseInt(data.quantity) : 0,
        autoRestock: data.autoRestock ? data.autoRestock : false,
        restockQuantity: data.restockQuantity
          ? parseInt(data.restockQuantity)
          : 0,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      const menuItem: MenuItem = await DataStore.save(
        new MenuItem(createInput as any)
      );

      // Create timeline
      const createTimelineInput: CreateTimelineInput = {
        actionName: TimelineActions.CREATE_MENU_ITEM,
        oldStatus: "",
        newStatus: menuItem.name,
        userId: userID,
        menuItemId: menuItem.id,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: session.sub,
        createdByName: session.name,
      };

      await timelinesCreate(createTimelineInput);

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err: Error | any) {
      showError(err.message || err);
    }
  }

  async function update(params: UpdateVariables) {
    const { id, data } = params;

    try {
      const original = await get({ id });

      let prices: any = new Set(original!.prices);
      let choiceGroups: any = new Set(original!.choiceGroups);
      const updatedChoiceGroups: string[] = extractSelectedCheckboxes(
        "choiceGroups_",
        data
      );

      if (data.prices && data.prices.length > 0) {
        for (let price of data.prices) {
          prices.add(price);
        }
        prices = Array.from(prices);
      }

      if (updatedChoiceGroups && updatedChoiceGroups.length > 0) {
        for (let choiceGroup of updatedChoiceGroups) {
          choiceGroups.add(choiceGroup);
        }
        choiceGroups = Array.from(choiceGroups);
      }

      const newMenuItem = MenuItem.copyOf(original!, (updated) => {
        updated.name = data.name ? data.name.toLowerCase() : original!.name;
        updated.description = data.description
          ? data.description
          : original!.description;
        updated.image = data.image
          ? data.image.id
            ? data.image.id
            : data.image
          : original!.image;
        updated.categoryID = data.categoryID
          ? data.categoryID
          : original!.categoryID;
        updated.outOfStock =
          data.outOfStock !== undefined
            ? data.outOfStock
            : original!.outOfStock;
        updated.enabled =
          data.enabled !== undefined ? data.enabled : original!.enabled;
        updated.symphonyID = data.symphonyID
          ? data.symphonyID
          : original!.symphonyID;
        updated.prices = data.prices ? prices : original!.prices;
        updated.choiceGroups = updatedChoiceGroups.length > 0
          ? choiceGroups
          : original!.choiceGroups;
        updated.hasLimitedQuantity = data.hasLimitedQuantity
          ? data.hasLimitedQuantity
          : false;
        updated.quantity = data.quantity ? parseInt(data.quantity) : 0;
        updated.autoRestock = data.autoRestock ? data.autoRestock : false;
        updated.restockQuantity = data.restockQuantity
          ? parseInt(data.restockQuantity)
          : 0;
      })

      console.log({newMenuItem})
      const model = await DataStore.save(newMenuItem);

      // Create timeline
      const createTimelineInput: CreateTimelineInput = {
        actionName: TimelineActions.UPDATE_MENU_ITEM,
        oldStatus: "",
        newStatus: "",
        menuItemId: model.id,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: session.sub,
        createdByName: session.name,
      };

      if (data.outOfStock && data.outOfStock !== original!.outOfStock) {
        if (data.outOfStock) {
          createTimelineInput.oldStatus = "Menu item in stock";
          createTimelineInput.newStatus = "Menu item out of stock";
        } else {
          createTimelineInput.oldStatus = "Menu item out of stock";
          createTimelineInput.newStatus = "Menu item in stock";
        }

        await timelinesCreate(createTimelineInput);
      }

      if (data.name && data.name !== original!.name) {
        await timelinesCreate({
          ...createTimelineInput,
          oldStatus: original!.name,
          newStatus: data.name,
        });
      }

      if (data.description && data.description !== original!.description) {
        createTimelineInput.oldStatus = original!.description
          ? original!.description
          : "";
        createTimelineInput.newStatus = data.description;

        await timelinesCreate(createTimelineInput);
      }

      if (data.categoryID && data.categoryID !== original!.categoryID) {
        // get category name
        createTimelineInput.oldStatus = original!.categoryID;
        createTimelineInput.newStatus = data.categoryID;

        await timelinesCreate(createTimelineInput);
      }

      if (data.symphonyID && data.symphonyID !== original!.symphonyID) {
        createTimelineInput.oldStatus = original!.symphonyID
          ? original!.symphonyID
          : "";
        createTimelineInput.newStatus = data.symphonyID;

        await timelinesCreate(createTimelineInput);
      }

      if (data.image && data.image !== original!.image) {
        // createTimelineInput.oldStatus = original!.image.filename;
        createTimelineInput.newStatus = data.image.filename;

        await timelinesCreate(createTimelineInput);
      }

      if (data.quantity && parseInt(data.quantity) !== original!.quantity) {
        createTimelineInput.oldStatus = original!.quantity
          ? original!.quantity.toString()
          : "0";
        createTimelineInput.newStatus = data.quantity;

        await timelinesCreate(createTimelineInput);
      }

      if (data.autoRestock && data.autoRestock !== original!.autoRestock) {
        if (data.autoRestock) {
          createTimelineInput.oldStatus = "Stop Restock";
          createTimelineInput.newStatus = "Restock items daily";
        } else {
          createTimelineInput.oldStatus = "Restock items daily";
          createTimelineInput.newStatus = "Stop Restock";
        }

        await timelinesCreate(createTimelineInput);
      }

      if (
        data.restockQuantity &&
        data.restockQuantity !== original!.restockQuantity
      ) {
        createTimelineInput.oldStatus = original!.restockQuantity
          ? original!.restockQuantity.toString()
          : "0";
        createTimelineInput.newStatus = data.restockQuantity;

        await timelinesCreate(createTimelineInput);
      }

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err: Error | any) {
      console.log({ err });
      showError(err.message || err);
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        MenuItem.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: MenuItemBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: GetVariables) {
    const { id } = params;

    try {
      await DataStore.delete(id as any);

      // dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: MenuItem[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "quantity",
      numeric: false,
      disablePadding: false,
      label: "Stock Quantity",
    },
    {
      id: "symphonyID",
      numeric: false,
      disablePadding: false,
      label: "Symphony ID",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "quantity", "symphonyID"];

  const api: any = {};

  api[`${listingName}Model`] = MenuItem as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetPrices`] = getPrices;
  api[`${listingName}GetChoiceGroups`] = getChoiceGroups;
  api[`${listingName}TrashPrice`] = trashPrice;
  api[`${listingName}TrashChoiceGroup`] = trashChoiceGroup;
  api[`${listingName}ChangeListing`] = (listing: MenuItem[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));

  return api;
};

export default useResource;
