import { useState } from "react";
import Box from "@mui/material/Box";

interface Props {
  alt: string;
  src: string;
  thumb: string;
}

const Image: React.FC<Props> = ({ alt, src, thumb }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <>
      <Box
        component="img"
        alt={alt}
        src={`https://wanijoseph.mo.cloudinary.net/kiosk-admin/${thumb}`}
        style={{ visibility: isLoaded ? "hidden" : "visible" }}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "100%",
          height: "100%",
          filter: "blur(20px)",
          transform: "translate(-50%, -50%) scale(1.1)",
          transition: "visibility 0ms ease 400ms",
        }}
      />
      <Box
        component="img"
        onLoad={() => {
          setIsLoaded(true);
        }}
        style={{ opacity: isLoaded ? 1 : 0 }}
        alt={alt}
        src={`https://wanijoseph.mo.cloudinary.net/kiosk-admin/${src}`}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "100%",
          height: "100%",
          transform: "translate(-50%, -50%)",
          transition: "opacity 400ms ease 0ms",
        }}
      />
    </>
  );
};

export default Image;
