import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch } from "react-redux";
import { setListing, setSelected } from "../store/ducks/adminRoles";
import { AdminRole } from "../models";
import { CreateAdminRoleInput } from "../models/api";
import {
  AdminRoleBulkTrashVariables,
  GetVariables,
  UpdateVariables,
  CreateVariables,
  ListingVariables,
  Option,
  HeadCell,
} from "../models/app";
import useApp from "./useApp";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm } = useApp();

  async function fetch(params: ListingVariables) {
    const { searchText, startIndex, limit } = params;

    try {
      const listing = await DataStore.query(
        AdminRole as any,
        (model: any) =>
          model
            .deleted("eq", "0")
            .or((model: any) =>
              model.name("contains", searchText.toLowerCase())
            ),
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;

    try {
      const single: AdminRole | undefined = await DataStore.query(
        AdminRole as any,
        id
      );

      return single;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateAdminRoleInput = {
        name: data.name.toLowerCase(),
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      if (data.description) createInput.description = data.description;

      await DataStore.save(new AdminRole(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      throw err;
    }
  }

  async function update(params: UpdateVariables) {
    const { id, data } = params;

    try {
      const original = await get({ id });

      await DataStore.save(
        AdminRole.copyOf(original!, (updated) => {
          updated.name = data.name ? data.name.toLowerCase() : original!.name;
          updated.description = data.description
            ? data.description
            : original!.description;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      throw err;
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        AdminRole.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      return `${singleName} has been moved to trash successfully`;
    } catch (err) {
      throw err;
    }
  }

  async function bulkTrash(params: AdminRoleBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: string) => {
      try {
        await trash({ id });
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    return `${ids.size} ${listingName} items has been moved to trash`;
  }

  async function remove(params: GetVariables) {
    const { id } = params;
    try {
      await DataStore.delete(id as any);

      // dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      return `${singleName} has been deleted successfully`;
    } catch (err: Error | any) {
      throw err;
    }
  }

  function options(listing: AdminRole[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name!, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "description"];

  const api: any = {};

  api[`${listingName}Model`] = AdminRole as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: AdminRole[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useResource;
