import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserPermissions } from "../models/app";
import { UserConcepts } from "../models";
import {
  clearSession,
  openSnackBar,
  setSession,
  openModal,
  closeModal,
  openDialog,
  closeDialog,
  setSelectedUser,
  setDateRange,
  setPaginateState,
  setUserPermissions,
  setUserConcepts,
  openSecondaryModal,
  closeSecondaryModal,
} from "../store/ducks/app";

const useApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchSession = async () => {
    const session: any = await Auth.currentSession();

    return session.idToken.payload;
  };

  function isStockManager(permissions: UserPermissions): boolean {
    if (permissions.canEditSettings) return false;
    if (permissions.canEditSystemUsers) return false;
    if (!permissions.canEditStock) return false;

    return true;
  }

  return {
    // initApp,
    fetchSession,
    isStockManager,
    setPaginateState: (paginateState: any) =>
      dispatch(setPaginateState(paginateState)),
    setPermissions: (permissions: UserPermissions) =>
      dispatch(setUserPermissions(permissions)),
    setConcepts: (payload: UserConcepts | null) =>
      dispatch(setUserConcepts(payload)),
    setSession: (session: any) => dispatch(setSession(session)),
    clearSession: () => {
      dispatch(clearSession());
    },
    showConfirm: (message: string) =>
      dispatch(openSnackBar(message, "success")),
    showError: (error: any) => {
      dispatch(openSnackBar(error.message ? error.message : error, "error"));
    },
    showWarning: (message: any) => {
      dispatch(openSnackBar(message, "warning"));
    },
    showInfo: (message: any) => {
      dispatch(openSnackBar(message, "info"));
    },
    openModal: (modalContent: any, modalContentId?: any) =>
      dispatch(openModal(modalContent, modalContentId)),
    showDeletePopup: (id: string, slug: string, resourceId: string) =>
      dispatch(openModal({ slug, resourceId }, id)),
    hideDeletePopup: () => dispatch(closeModal()),
    modalClose: () => dispatch(closeModal()),
    navigateTo: (url: string) => navigate(url),
    navigateBack: () => navigate(-1),
    openDialog: () => dispatch(openDialog()),
    closeDialog: () => dispatch(closeDialog()),
    setSelectedUser: (user: any) => dispatch(setSelectedUser(user)),
    changeDateRange: (dateRange: any) => dispatch(setDateRange(dateRange)),
    openSecondaryModal: (
      secondaryModalContent: any,
      secondaryModalContentId?: any
    ) =>
      dispatch(
        openSecondaryModal(secondaryModalContent, secondaryModalContentId)
      ),
    showSecondaryPopup: (id: string, slug: string, resourceId: string) =>
      dispatch(openSecondaryModal({ slug, resourceId }, id)),
    hideSecondaryPopup: () => dispatch(closeSecondaryModal()),
  };
};

export default useApp;
