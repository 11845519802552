import { CreateTimelineInput } from "./../models/api";
import {
  GetVariables,
  TimelineBulkTrashVariables,
  UpdateVariables,
} from "./../models/app";
import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setSearchText,
  setSelected,
} from "../store/ducks/timeline";
import { HeadCell } from "../models/dataTable";
import { Timeline } from "../models";
import useApp from "./useApp";
import { TimelineGetVariables, TimelineListingVariables } from "../models/app";
import { Order } from "../models";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );

  async function fetch(props: TimelineListingVariables) {
    const { startIndex, limit, userId } = props;
    // console.log("userId:", userId);

    try {
      const listing = await DataStore.query(
        Timeline as any,
        (model: any) => {
          model.deleted("eq", "0");

          if (userId) model.createdByID("eq", userId);

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      dispatch(setListing(listing));

      return listing;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;

    try {
      const single: Timeline | undefined = await DataStore.query(
        Timeline as any,
        id
      );

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateTimelineInput) {
    const timeline = await DataStore.save(new Timeline(params as any));

    return timeline;
  }

  async function update(params: UpdateVariables) {
    const { id, data } = params;

    try {
      const original = await get({ id });

      await DataStore.save(
        Timeline.copyOf(original!, (updated) => {
          updated.actionName = data.actionName
            ? data.actionName
            : original!.actionName;
          updated.oldStatus = data.oldStatus
            ? data.oldStatus
            : original!.oldStatus;
          updated.newStatus = data.newStatus
            ? data.newStatus
            : original!.newStatus;
          updated.userId = data.userId ? data.userId : original!.userId;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: TimelineGetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        Timeline.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: TimelineBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: TimelineGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  async function exportAll(params: TimelineListingVariables) {
    const data = await fetch(params);

    let exportedData = [];

    for (let entry of data!) {
      let row: any = { ...entry };
      exportedData.push(row);
    }

    return exportedData;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "actionName",
      numeric: false,
      disablePadding: false,
      label: "Action",
    },
    {
      id: "oldStatus",
      numeric: false,
      disablePadding: false,
      label: "Old Status",
    },
    {
      id: "newStatus",
      numeric: false,
      disablePadding: false,
      label: "New Status",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["actionName", "oldStatus", "newStatus"];
  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Model`] = Order as any;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeSearchText`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));
  api[`${listingName}Export`] = exportAll;

  return api;
};

export default useResource;
