import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch } from "react-redux";
import { setListing, setSelected } from "../store/ducks/adminGroups";
import { AdminGroup } from "../models";
import { CreateAdminGroupInput } from "../models/api";
import { extractSelectedCheckboxes } from "../helpers/utils";
import {
  AdminGroupBulkTrashVariables,
  GetVariables,
  UpdateVariables,
  CreateVariables,
  Option,
  HeadCell,
  AdminGroupListingVariables,
} from "../models/app";
import useApp from "./useApp";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm } = useApp();

  async function fetch(params: AdminGroupListingVariables) {
    const { searchText, startIndex, limit, adminID } = params;

    try {
      const listing = await DataStore.query(
        AdminGroup as any,
        (model: any) => {
          model.deleted("eq", "0");

          if (adminID) model.users("contains", adminID);

          if (searchText.length > 0)
            model.name("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function fetchCurrentUser(session: string) {
    try {
      const listing = await DataStore.query(
        AdminGroup as any,
        (model: any) => model.deleted("eq", "0").users("contains", session),
        {
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;

    try {
      const single: AdminGroup | undefined = await DataStore.query(
        AdminGroup as any,
        id
      );

      return single;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;
    const roles: string[] = extractSelectedCheckboxes("roles_", data);
    const users: string[] = extractSelectedCheckboxes("users_", data);

    try {
      const createInput: CreateAdminGroupInput = {
        name: data.name.toLowerCase(),
        roles: roles,
        users: users,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      if (data.description) createInput.description = data.description;

      await DataStore.save(new AdminGroup(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      throw err;
    }
  }

  async function update(params: UpdateVariables) {
    const { id, data } = params;
    const roles: string[] = extractSelectedCheckboxes("roles_", data);
    const users: string[] = extractSelectedCheckboxes("users_", data);

    try {
      const original = await get({ id });

      await DataStore.save(
        AdminGroup.copyOf(original!, (updated) => {
          updated.name = data.name ? data.name.toLowerCase() : original!.name;
          updated.description = data.description
            ? data.description
            : original!.description;
          updated.roles = roles;
          updated.users = users;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      throw err;
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        AdminGroup.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      throw err;
    }
  }

  async function bulkTrash(params: AdminGroupBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: string) => {
      try {
        await trash({ id });
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: GetVariables) {
    const { id } = params;

    try {
      await DataStore.delete(id as any);

      // dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      return `${singleName} has been deleted successfully`;
    } catch (err: Error | any) {
      throw err;
    }
  }

  function options(listing: AdminGroup[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name!, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "description"];

  const api: any = {};

  api[`${listingName}Model`] = AdminGroup as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchCurrentUser`] = fetchCurrentUser;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: AdminGroup[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useResource;
