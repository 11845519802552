import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { setListing, setSelected } from "../store/ducks/choiceGroup";
import { Choice, ChoiceGroup } from "../models";
import { HeadCell } from "../models/dataTable";
import { CreateChoiceGroupInput } from "../models/api";
import useApp from "./useApp";
import {
  ChoiceGroupBulkTrashVariables,
  GetVariables,
  UpdateVariables,
  CreateVariables,
  ListingVariables,
  Option,
} from "../models/app";
import useChoice from "./useChoice";
import { extractSelectedCheckboxes } from "../helpers/utils";

const useResource = (listingName: string, singleName: string) => {
  const conceptsSelected = useSelector((state: any) => state.concepts.selected);
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const { choicesGet, choicesTrash } = useChoice("choices", "choice");

  async function fetch(params: ListingVariables) {
    const { searchText, startIndex, limit } = params;

    try {
      const listing = await DataStore.query(
        ChoiceGroup as any,
        (model: any) => {
          model.deleted("eq", "0");

          if (searchText.length > 0)
            model.name("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;

    try {
      const single: ChoiceGroup | undefined = await DataStore.query(
        ChoiceGroup as any,
        id
      );

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function getChoices(resourceId: string) {
    try {
      const choices: Choice[] = [];

      const single: ChoiceGroup | undefined = await DataStore.query(
        ChoiceGroup as any,
        resourceId
      );

      if (!single) return choices;

      if (single.choices?.length === 0) return choices;

      if (single.choices && single.choices.length > 0) {
        for (let choiceID of single.choices) {
          let choice = await choicesGet({ id: choiceID });
          if (choice && choice.deleted === "0") choices.push(choice);
        }

        return choices;
      }
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function trashChoice(resourceId: string, choiceId: string) {
    try {
      const single: ChoiceGroup | undefined = await DataStore.query(
        ChoiceGroup as any,
        resourceId
      );

      let choices: any[] = single && single.choices ? [...single.choices] : [];

      let choice = choices.indexOf(choiceId);

      if (choice > -1) {
        choices.splice(choice, 1);
      }

      await update({ id: resourceId, data: { choices } });
      await choicesTrash({ id: choiceId });
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.name) throw new Error("Name is required");
    const choices: string[] = extractSelectedCheckboxes("choices_", data);

    try {
      const createInput: CreateChoiceGroupInput = {
        conceptID: conceptsSelected,
        name: data.name,
        required: data.required !== undefined ? data.required : false,
        maxNumberOfChoices: data.maxNumberOfChoices
          ? parseInt(data.maxNumberOfChoices)
          : 0,
        minNumberOfChoices: data.minNumberOfChoices
          ? parseInt(data.minNumberOfChoices)
          : 0,
        precedence: data.precedence ? data.precedence : "0",
        choices: choices ? choices : [],
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      const choiceGroup = await DataStore.save(
        new ChoiceGroup(createInput as any)
      );

      showConfirm(`New ${singleName} has been created successfully`);
      return choiceGroup;
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: UpdateVariables) {
    const { id, data } = params;

    try {
      const original = await get({ id });

      let choices: any = new Set(original!.choices);
      const updatedChoices: string[] = extractSelectedCheckboxes(
        "choices_",
        data
      );

      if (updatedChoices && updatedChoices.length > 0) {
        for (let choice of updatedChoices) {
          choices.add(choice);
        }
        choices = Array.from(choices);
      }

      await DataStore.save(
        ChoiceGroup.copyOf(original!, (updated) => {
          updated.name = data.name ? data.name : original!.name;
          updated.required =
            data.required !== undefined ? data.required : original!.required;
          updated.maxNumberOfChoices = data.maxNumberOfChoices
            ? parseInt(data.maxNumberOfChoices)
            : original!.maxNumberOfChoices;
          updated.minNumberOfChoices = data.minNumberOfChoices
            ? parseInt(data.minNumberOfChoices)
            : original!.minNumberOfChoices;
          updated.precedence = data.precedence
            ? data.precedence
            : original!.precedence;
          updated.choices = choices ? choices : original!.choices;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        ChoiceGroup.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: ChoiceGroupBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: GetVariables) {
    const { id } = params;

    try {
      await DataStore.delete(id as any);

      // dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: ChoiceGroup[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const api: any = {};

  api[`${listingName}Model`] = ChoiceGroup as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetChoices`] = getChoices;
  api[`${listingName}TrashChoice`] = trashChoice;
  api[`${listingName}ChangeListing`] = (listing: ChoiceGroup[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));

  return api;
};

export default useResource;
