import {
  CategoryBulkTrashVariables,
  GetVariables,
  UpdateVariables,
  ListingByConceptVariables,
  Option,
} from "./../models/app";
import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { setListing, setSelected } from "../store/ducks/category";
import { Category } from "../models";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { CreateCategoryInput, CreateTimelineInput } from "../models/api";
import { CreateVariables } from "../models/app";
import useTimeline from "./useTimeline";
import { TimelineActions } from "../constants/enums";

const useResource = (listingName: string, singleName: string) => {
  const session = useSelector((state: any) => state.app.session);
  const conceptsSelected = useSelector((state: any) => state.concepts.selected);
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const { timelinesCreate } = useTimeline("timelines", "timeline");

  async function fetch(params: ListingByConceptVariables) {
    const { conceptID, searchText, startIndex, limit } = params;

    try {
      const listing = await DataStore.query(
        Category as any,
        (model: any) => {
          // model.deleted("eq", "0");
          model.conceptID("eq", conceptID);

          if (searchText.length > 0)
            model.name("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;

    try {
      const single: Category | undefined = await DataStore.query(
        Category as any,
        id
      );

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateCategoryInput = {
        conceptID: conceptsSelected,
        name: data.name.toLowerCase(),
        image: data.image ? (data.image.id ? data.image.id : data.image) : "",
        precedence: data.precedence ? data.precedence : "0",
        symphonyID: data.symphonyID ? data.symphonyID : "0",
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      const category: Category = await DataStore.save(
        new Category(createInput as any)
      );

      // Create timeline
      const createTimelineInput: CreateTimelineInput = {
        actionName: TimelineActions.CREATE_CATEGORY,
        oldStatus: "",
        newStatus: category.name,
        userId: session.sub,
        categoryId: category.id,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: session.sub,
        createdByName: session.name,
      };

      await timelinesCreate(createTimelineInput);

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: UpdateVariables) {
    const { id, data } = params;

    try {
      const original = await get({ id });

      await DataStore.save(
        Category.copyOf(original!, (updated) => {
          updated.name = data.name ? data.name.toLowerCase() : original!.name;
          updated.image = data.image
            ? data.image.id
              ? data.image.id
              : data.image
            : original!.image;
          updated.precedence = data.precedence
            ? data.precedence
            : original!.precedence;
          updated.symphonyID = data.symphonyID
            ? data.symphonyID
            : original!.symphonyID;
        })
      );

      var createTimelineInput: CreateTimelineInput = {
        actionName: TimelineActions.UPDATE_CATEGORY,
        oldStatus: "",
        newStatus: "",
        categoryId: id,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: session.sub,
        createdByName: session.name,
      };

      if (data.name && data.name !== original!.name) {
        createTimelineInput.actionName =
          TimelineActions.UPDATE_CATEGORY + " name";
        createTimelineInput.oldStatus = original!.name;
        createTimelineInput.newStatus = data.name;
        await timelinesCreate(createTimelineInput);
      }

      if (data.precedence && data.precedence !== original!.precedence) {
        createTimelineInput.actionName =
          TimelineActions.UPDATE_CATEGORY + " order";
        createTimelineInput.oldStatus = original!.precedence;
        createTimelineInput.newStatus = data.precedence;
        await timelinesCreate(createTimelineInput);
      }

      if (data.symphonyID && data.symphonyID !== original!.symphonyID) {
        createTimelineInput.actionName =
          TimelineActions.UPDATE_CATEGORY + " symphony ID";
        createTimelineInput.oldStatus = original!.symphonyID + "";
        createTimelineInput.newStatus = data.symphonyID;
        await timelinesCreate(createTimelineInput);
      }

      if (data.image && data.image !== original!.image) {
        createTimelineInput.actionName =
          TimelineActions.UPDATE_CATEGORY + " image";
        createTimelineInput.oldStatus = original!.image + "";
        createTimelineInput.newStatus = data.image.filename;
        await timelinesCreate(createTimelineInput);
      }

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        Category.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: CategoryBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function restore(params: GetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        Category.copyOf(original!, (updated) => {
          updated.deleted = "0";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkRestore(params: CategoryBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await restore(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: GetVariables) {
    const { id } = params;

    try {
      await DataStore.delete(id as any);

      // dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  async function exportAll(params: ListingByConceptVariables) {
    const data = await fetch(params);

    let exportedData = [];

    for (let entry of data!) {
      let row: any = { ...entry };
      exportedData.push(row);
    }

    return exportedData;
  }

  function options(listing: Category[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "deleted",
      numeric: false,
      disablePadding: false,
      label: "Deleted",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "deleted"];

  const api: any = {};

  api[`${listingName}Model`] = Category as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Restore`] = restore;
  api[`${listingName}BulkRestore`] = bulkRestore;

  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Category[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));
  api[`${listingName}Export`] = exportAll;

  return api;
};

export default useResource;
