import { Box, Button, Paper, Typography, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useApp from "../../../hooks/useApp";
import DateRange from "../DateRange/DateRange";
import { useSelector } from "react-redux";

interface Props {
  title: string;
  selection: string;
}

const DateRangePicker: React.FC<Props> = ({ title, selection }) => {
  const dateRange = useSelector((state: any) => state.app.dateRange);
  const { hideDeletePopup, changeDateRange } = useApp();
  const tablet = useMediaQuery((theme: any) => theme.breakpoints.down("md"));

  const handleChange = (item: any) => {
    changeDateRange([item.selection]);

    if (selection === "Single") hideDeletePopup();
  };

  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: 892,
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
        "& .rdrDefinedRangesWrapper": {
          display: { xs: "none", md: "block" },
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: "grey.300",
          padding: "8px 16px",
          color: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <CloseIcon
          aria-label="close modal"
          onClick={hideDeletePopup}
          sx={{ cursor: "pointer" }}
        />
      </Box>
      <Paper
        square
        elevation={0}
        sx={{ border: "1px solid", borderColor: "divider" }}
      >
        <DateRange
          setLimit={false}
          state={dateRange}
          handleChange={(item: any) => handleChange(item)}
          months={tablet ? 1 : 2}
        />
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "flex-end",
            borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Button
            disableElevation
            variant="text"
            size="small"
            onClick={hideDeletePopup}
          >
            Cancel
          </Button>
          {selection === "Multiple" && (
            <Button
              disableElevation
              variant="text"
              size="small"
              onClick={hideDeletePopup}
              sx={{ ml: 2 }}
            >
              OK
            </Button>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default DateRangePicker;
