export enum Directions {
  RTL = "rtl",
  LTR = "ltr",
}

export enum Languages {
  ENGLISH = "en",
  ARABIC = "ar",
}

export enum Pages {
  LOGIN = "",
  REGISTER = "register",
  VERIFY = "verify",
  FORGOT_PASSWORD = "forget-password",
  DASHBOARD = "overview",
  ACCOUNTS = "accounts",
  ADMINS = "admins",
  LANGUAGES = "languages",
  FEATURES = "features",
  PAGES = "pages",
  MEDIA_LIBRARY = "media-library",
  MESSAGES = "messages",
  MENUS = "menus",
  POSTS = "posts",
  SETTINGS = "settings",
  SLIDES = "slides",
  COURSES = "courses",
  MODULES = "modules",
  QUIZZES = "quizzes",
  QUIZ_QUESTIONS = "quiz-questions",
  QUESTIONS = "questions",
  QUESTIONS_TYPES = "questions-types",
  GROUPS = "groups",
  GROUP_SETTINGS = "group-settings",
  INTERESTS = "interests",
  BOOKINGS = "bookings",
  BOOKINGS_LIST = "bookings-history",
  FLAGS = "flags",
  GUESTS = "guests",
  GUESTS_PROFILE = "guests-profile",
  CONCEPTS = "concepts",
  BRANCHES = "branches",
  RESERVATION_STATUS = "statuses",
  TIME_SLOTS = "time-slots",
  AREAS = "areas",
  COMMENTS = "comments",
  PLAN_ITEMS = "plan-items",
  TABLES = "tables",
  TIMELINES = "timelines",
  SERVING_AREAS = "serving-areas",
  OBJECTS = "objects",
  CUSTOMER_STATS = "customerStats",
  FLOOR_EDITOR = "floor-editor",
  SOCIAL_LINKS = "social-links",
  CALL_CENTER = "call-center",
  CALL_CENTER_SETTINGS = "call-center-settings",
  BOOKINGS_TABLES = "bookings-tables",
  RESET_PASSWORD = "reset-password",
  NOTIFICATION_SETTINGS = "notification-settings",
  NOTIFICATIONS = "notifications",
  BOOKING_CONFIRMATION = "reservations",
  MENU_ITEMS = "menu-items",
  CATEGORIES = "categories",
  PRICES = "prices",
  CHOICES = "choices",
  CHOICE_GROUPS = "choice-groups",
  SCREEN_SAVER = "screen-savers",
  BANNERS = "banners",
  ORDERS = "orders",
  ORDER_ITEMS = "order-items",
  KIOSKS = "kiosks",
  KIOSK_STATUES = "kioskStatuses",
  SERVICES = "services",
  PAYMENT_METHODS = "payment-methods",
  ADMIN_ROLES = "admin-roles",
  ADMIN_GROUPS = "admin-groups",
  VIDEOS = "videos",
}

export enum Orders {
  ASC = "asc",
  DSC = "desc",
}

export enum FormActions {
  ADD = "Add",
  EDIT = "Edit",
}

export enum QuestionTypes {
  TRUE_FALSE = "True False",
  CHOOSE = "Choose",
  CHOOSE_IMAGE = "Choose with Image",
  TEXT_IMAGE = "Text with Image",
  OPTIONS_IMAGE = "Options as Image",
  COMPLETE_IMAGE = "Complete with Image",
}

export enum TimelineActions {
  LOG_IN = "User Login",
  LOG_OUT = "User Logout",
  CREATE_KIOSK = "Create Kiosk",
  UPDATE_KIOSK = "Update Kiosk",
  DELETE_KIOSK = "Delete Kiosk",
  CREATE_MENU_ITEM = "Create Menu Item",
  UPDATE_MENU_ITEM = "Update Menu Item",
  DELETE_MENU_ITEM = "Delete Menu Item",
  CREATE_CATEGORY = "Create Category",
  UPDATE_CATEGORY = "Update Category",
  DELETE_CATEGORY = "Delete Category",
}

export enum Channels {
  TELEPHONE_CHANNEL = "telephone",
  ANDROID_CHANNEL = "android",
  IPHONE_CHANNEL = "iphone",
  WEB_CHANNEL = "web",
}

export enum ResourceTypes {
  CUSTOMER = "customer",
  BOOKING = "booking",
}

export enum BookingTimelineActions {
  TIME_SLOT = "Update time slot",
  STATUS = "Update Booking Status",
  TABLE = "Update Booking Table",
  DATE = "Update Booking Date",
  GUEST_COUNT = "Update Guest Count",
  RESERVATION_EMAIL = "Send Reservation Email",
}

export enum AuthErrors {
  USER_EXISTS = "UsernameExistsException",
}

export enum AdminsGroups {
  FULL_ACCESS = "full access",
  LIMITED_ACCESS = "limitedaccess",
  CALL_CENTER_AGENT = "callCenterAgent",
  MANAGER = "manager",
  HOST = "host",
}

export enum AdminsConstants {
  ADMINS_API = "AdminQueries",
}

export enum AdminsApiPaths {
  LIST_USERS = "/listUsers",
  LIST_USERS_IN_GROUP = "/listUsersInGroup",
  LIST_GROUPS_FOR_USER = "/listGroupsForUser",
  GET_USER = "/getUser",
  LIST_GROUPS = "/listGroups",
  ADD_USER_TO_GROUP = "/addUserToGroup",
  REMOVE_USER_FROM_GROUP = "/removeUserFromGroup",
  DISABLE_USER = "/disableUser",
  ENABLE_USER = "/enableUser",
}

export enum StatuesIds {
  CANCELLED = "151a9e09-5d09-4f07-9928-90cccf46f41d",
  CLOSED = "1ca36387-69d5-4dba-be42-634b34493729",
}

export const NOTIFICATION_CHANNELS = [
  { label: "email", value: "email" },
  { label: "sms", value: "sms" },
];

export const ENCRYPTION_KEY =
  "123456dasflasdldfl789asjdflkj3fadsfsdlfds0123456";

export enum ADMIN_ROLES {
  SETTINGS = "settings_access",
  DASHBOARD = "dashboard_access",
  USERS = "users_access",
  TIMELINES = "timelines_access",
  STOCK = "can_edit_stock_status",
}
