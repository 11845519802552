import { lazy, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import ErrorBoundary from "./components/UI/ErrorBoundary";
import MainLoader from "./components/UI/MainLoader";
import MainModal from "./components/UI/MainModal";
import SecondaryModal from "./components/UI/SecondaryModal";
import MainSnackbar from "./components/UI/MainSnackbar";
import useApp from "./hooks/useApp";
import useLoading from "./hooks/useLoading";
import useConcept from "./hooks/useConcept";
import useAccount from "./hooks/useAccount";
import useFeature from "./hooks/useFeature";
import { useSelector } from "react-redux";
import {
  AccountListingVariables,
  GetVariables,
  ListingByAccountVariables,
  ListingVariables,
  UserPermissions,
} from "./models/app";
import { Concept } from "./models";
import useAdminGroup from "./hooks/useAdminGroup";
import { ADMIN_ROLES } from "./constants/enums";
import useAdminRole from "./hooks/useAdminRole";
import { DataStore, Hub } from "aws-amplify";
import { getDomainName } from "./helpers/utils";

const Admin = lazy(() => import("./containers/Dashboard"));
const Auth = lazy(() => import("./containers/Auth"));

const App: React.FC = () => {
  const modalOpen = useSelector((state: any) => state.app.modalOpen);
  const secondaryModalOpen = useSelector(
    (state: any) => state.app.secondaryModalOpen
  );
  const snackBarOpen = useSelector((state: any) => state.app.snackBarOpen);
  const userConcepts = useSelector((state: any) => state.app.concepts);
  const { loading, changeLoading } = useLoading();
  const { fetchSession, setSession, clearSession, setPermissions } = useApp();
  const { accountsFetch, accountsChangeSelected } = useAccount(
    "accounts",
    "account"
  );
  const { conceptsFetch, conceptsChangeListing, conceptsChangeSelected } =
    useConcept("concepts", "concept");
  const { featuresFetch, featuresChangeListing } = useFeature(
    "features",
    "feature"
  );
  const { adminGroupsFetchCurrentUser } = useAdminGroup(
    "adminGroups",
    "adminGroup"
  );
  const { adminRolesGet } = useAdminRole("adminRoles", "adminRole");

  const initApp = async () => {
    try {
      const session = await fetchSession();
      const filteredConcepts: Concept[] = [];

      const listingParams: ListingVariables = {
        searchText: "",
        startIndex: 0,
        limit: 1000,
      };

      const accountsParams: AccountListingVariables = {
        ...listingParams,
        domain: getDomainName(),
      };

      const data = await Promise.all([
        accountsFetch(accountsParams),
        featuresFetch(listingParams),
      ]);

      if (data[0].length === 0)
        throw new Error("There is no account given this email");

      const byAccountParams: ListingByAccountVariables = {
        ...listingParams,
        accountID: data[0][0].id,
      };

      const allConcepts: Concept[] = await conceptsFetch(byAccountParams);
      const userGroup = await adminGroupsFetchCurrentUser(session.sub);

      const userPermissions: UserPermissions = {
        canSeeDashboard: false,
        canEditSystemUsers: false,
        canEditSettings: false,
        canEditStock: false,
        groups: [],
        concepts: [],
      };

      if (userGroup && userGroup.length > 0) {
        userPermissions.groups.push(userGroup[0].name);
        const userRoles = userGroup[0].roles;

        for (let role of userRoles) {
          const adminRolesParams: GetVariables = { id: role };

          let userRole = await adminRolesGet(adminRolesParams);

          switch (userRole.name) {
            case ADMIN_ROLES.SETTINGS:
              userPermissions.canEditSettings = true;
              break;
            case ADMIN_ROLES.DASHBOARD:
              userPermissions.canSeeDashboard = true;
              break;
            case ADMIN_ROLES.USERS:
              userPermissions.canEditSystemUsers = true;
              break;
            case ADMIN_ROLES.STOCK:
              userPermissions.canEditStock = true;
              break;
            default:
              break;
          }
        }
      }

      setPermissions(userPermissions);

      if (userConcepts) {
        for (var concept of allConcepts) {
          if (userConcepts.concepts.includes(concept.id)) {
            filteredConcepts.push(concept);
          }
        }
      } else {
        for (let concept of allConcepts) {
          filteredConcepts.push(concept);
        }
      }

      conceptsChangeListing([...filteredConcepts]);

      if (filteredConcepts.length > 0) {
        const selectedConcept: string | null =
          localStorage.getItem("selectConcept");

        if (selectedConcept) {
          conceptsChangeSelected(selectedConcept);
        } else {
          conceptsChangeSelected(filteredConcepts[0].id);
        }
      }

      accountsChangeSelected(data[0][0]);
      featuresChangeListing(data[1]);
      setSession(session);
      changeLoading(false);
    } catch (err: Error | any) {
      clearSession();
      changeLoading(false);
    }
  };

  useEffect(() => {
    // Create listener that will stop observing the model once the sync process is done
    const removeListener = Hub.listen("datastore", async (capsule) => {
      const {
        payload: { event },
      } = capsule;

      if (event === "ready") initApp();
    });

    // Start the DataStore, this kicks-off the sync process.
    DataStore.start();

    return () => {
      removeListener();
    };

    // eslint-disable-next-line
  }, []);

  if (loading) return <MainLoader />;

  return (
    <ErrorBoundary>
      <DndProvider backend={HTML5Backend}>
        <Suspense fallback={<MainLoader />}>
          <Routes>
            <Route path="/*" element={<Auth />} />
            <Route path="dashboard/*" element={<Admin />} />
          </Routes>
        </Suspense>
      </DndProvider>
      {modalOpen && <MainModal />}
      {secondaryModalOpen && <SecondaryModal />}
      {snackBarOpen && <MainSnackbar />}
    </ErrorBoundary>
  );
};

export default App;
