import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
import { ADMIN_ROLES, Pages } from "../constants/enums";
import { getDomainName, clearBrowser } from "../helpers/utils";
import { Concept } from "../models";
import {
  AccountListingVariables,
  GetVariables,
  ListingByAccountVariables,
  ListingVariables,
  UserPermissions,
} from "../models/app";
import useAccount from "./useAccount";
import useAdminGroup from "./useAdminGroup";
import useAdminRole from "./useAdminRole";
import useApp from "./useApp";
import useConcept from "./useConcept";
import useFeature from "./useFeature";

const useAuth = () => {
  const userConcepts = useSelector((state: any) => state.app.concepts);
  const {
    showError,
    showConfirm,
    setSession,
    clearSession,
    navigateTo,
    setPermissions,
  } = useApp();
  const { accountsFetch, accountsChangeSelected } = useAccount(
    "accounts",
    "account"
  );
  const { conceptsFetch, conceptsChangeListing, conceptsChangeSelected } =
    useConcept("concepts", "concept");
  const { featuresFetch, featuresChangeListing } = useFeature(
    "features",
    "feature"
  );
  const { adminGroupsFetchCurrentUser } = useAdminGroup(
    "adminGroups",
    "adminGroup"
  );
  const { adminRolesGet } = useAdminRole("adminRoles", "adminRole");

  async function register(data: any) {
    try {
      await Auth.signUp({
        username: `${getDomainName()}_${data.email}`,
        password: data.password,
        attributes: {
          name: data.name,
          email: `${getDomainName()}_${data.email}`,
        },
      });

      navigateTo("/");
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
    }
  }

  async function login(formData: any) {
    try {
      const user = await Auth.signIn({
        username: `${getDomainName()}_${formData.email}`,
        password: formData.password,
      });

      const filteredConcepts: Concept[] = [];

      const listingParams: ListingVariables = {
        searchText: "",
        startIndex: 0,
        limit: 1000,
      };

      const accountsParams: AccountListingVariables = {
        ...listingParams,
        domain: getDomainName(),
      };

      const data = await Promise.all([
        accountsFetch(accountsParams),
        featuresFetch(listingParams),
      ]);

      const byAccountParams: ListingByAccountVariables = {
        ...listingParams,
        accountID: data[0][0].id,
      };

      const allConcepts: Concept[] = await conceptsFetch(byAccountParams);
      const userGroup = await adminGroupsFetchCurrentUser(
        user.signInUserSession.idToken.payload.sub
      );

      const userPermissions: UserPermissions = {
        canSeeDashboard: false,
        canEditSystemUsers: false,
        canEditSettings: false,
        canEditStock: false,
        groups: [],
        concepts: [],
      };

      if (userGroup && userGroup.length > 0) {
        userPermissions.groups.push(userGroup[0].name);
        const userRoles = userGroup[0].roles;

        for (let role of userRoles) {
          const adminRolesParams: GetVariables = { id: role };

          let userRole = await adminRolesGet(adminRolesParams);

          switch (userRole.name) {
            case ADMIN_ROLES.SETTINGS:
              userPermissions.canEditSettings = true;
              break;
            case ADMIN_ROLES.DASHBOARD:
              userPermissions.canSeeDashboard = true;
              break;
            case ADMIN_ROLES.USERS:
              userPermissions.canEditSystemUsers = true;
              break;
            case ADMIN_ROLES.STOCK:
              userPermissions.canEditStock = true;
              break;
            default:
              break;
          }
        }
      }
      setPermissions(userPermissions);

      if (userConcepts) {
        for (var concept of allConcepts) {
          if (userConcepts.concepts.includes(concept.id)) {
            filteredConcepts.push(concept);
          }
        }
      } else {
        for (let concept of allConcepts) {
          filteredConcepts.push(concept);
        }
      }

      conceptsChangeListing([...filteredConcepts]);

      if (filteredConcepts.length > 0) {
        const selectedConcept: string | null =
          localStorage.getItem("selectConcept");

        if (selectedConcept) {
          conceptsChangeSelected(selectedConcept);
        } else {
          conceptsChangeSelected(filteredConcepts[0].id);
        }
      }

      accountsChangeSelected(data[0][0]);
      featuresChangeListing(data[1]);
      setSession(user.signInUserSession.idToken.payload);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
    }
  }

  async function logout() {
    try {
      await Auth.signOut();

      clearSession();
      await clearBrowser();
      navigateTo("/");
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
    }
  }

  async function forgetPassword(data: any) {
    try {
      // Send confirmation code to user's email
      await Auth.forgotPassword(`${getDomainName()}_${data.email}`);
      showConfirm("Verification code has been sent to your email.");
      navigateTo(`/${Pages.RESET_PASSWORD}`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
    }
  }

  async function forgetPasswordSubmit(data: any) {
    try {
      await Auth.forgotPasswordSubmit(
        `${getDomainName()}_${data.email}`,
        data.code,
        data.newPassword
      );
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
    }
  }

  return { login, register, logout, forgetPassword, forgetPasswordSubmit };
};

export default useAuth;
