import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

import ButtonLoader from "../ButtonLoader";
import Tabs from "../Tabs";
import MediaFiles from "../MediaFiles";
import useAttachment from "../../../hooks/useAttachment";
import { Attachment } from "../../../models";
import { useSelector } from "react-redux";

interface Props {
  title: string;
  attachment: any;
  closeModal: any;
  changeAttachment?: any;
}

const ImagePicker: React.FC<Props> = ({
  title,
  attachment,
  closeModal,
  changeAttachment,
}) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const session = useSelector((state: any) => state.app.session);
  const mediaLibraryListing = useSelector(
    (state: any) => state.mediaLibrary.listing
  );
  const mediaLibrarySelected = useSelector(
    (state: any) => state.mediaLibrary.selected
  );
  const { attachmentsCreate, attachmentsDelete } = useAttachment(
    "attachments",
    "attachment"
  );

  const update = async () => {
    setIsUpdating(true);

    console.log(attachment);

    await attachmentsDelete({ id: attachment.id });

    for (let mediaId of mediaLibrarySelected) {
      const mediaFile: any = mediaLibraryListing.find(
        (file: any) => file.id === mediaId
      );

      const createInput: any = {
        fileUrl: mediaFile.fileUrl,
        filename: mediaFile.filename,
        alternativeText: mediaFile.alternativeText,
        filetype: mediaFile.filetype,
        fileSize: mediaFile.fileSize,
        caption: mediaFile.caption,
        description: mediaFile.description,
        mediaID: mediaId,
      };

      const attachment: Attachment = await attachmentsCreate({
        userID: session.sub,
        userName: session.name,
        data: createInput,
      });

      changeAttachment(attachment);
    }

    setIsUpdating(false);
    closeModal();
  };

  const create = async () => {
    setIsUpdating(true);

    for (let mediaId of mediaLibrarySelected) {
      const mediaFile: any = mediaLibraryListing.find(
        (file: any) => file.id === mediaId
      );

      const createInput: any = {
        fileUrl: mediaFile.fileUrl,
        filename: mediaFile.filename,
        alternativeText: mediaFile.alternativeText,
        filetype: mediaFile.filetype,
        fileSize: mediaFile.fileSize,
        caption: mediaFile.caption,
        description: mediaFile.description,
        mediaID: mediaId,
      };

      const attachment: Attachment = await attachmentsCreate({
        userID: session.sub,
        userName: session.name,
        data: createInput,
      });

      changeAttachment(attachment);
    }

    setIsUpdating(false);
    closeModal();
  };

  return (
    <Box
      sx={{
        width: "96%",
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
      }}
    >
      <Box
        sx={{
          background: (theme) => theme.palette.grey[300],
          padding: "8px 16px",
          color: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <CloseIcon
          aria-label="close modal"
          onClick={closeModal}
          sx={{ cursor: "pointer" }}
        />
      </Box>
      <Paper
        square
        elevation={0}
        sx={{
          border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Box sx={{ height: "calc(94vh - 120px)" }}>
          <Tabs
            tabs={["Media Library"]}
            panels={[<MediaFiles multiple={false} />]}
            activeTab={0}
          />
        </Box>
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "flex-end",
            borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Button
            disableElevation
            variant="contained"
            size="small"
            onClick={attachment ? update : create}
            sx={{ textTransform: "capitalize" }}
            disabled={mediaLibrarySelected.size === 0 || isUpdating}
            endIcon={isUpdating && <ButtonLoader size={20} />}
          >
            {`Set ${title}`}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default ImagePicker;
