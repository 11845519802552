import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch } from "react-redux";
import { setListing, setSelected } from "../store/ducks/attachment";
import { Attachment } from "../models";
import {
  AttachmentBulkTrashVariables,
  GetVariables,
  UpdateVariables,
  CreateVariables,
  ListingVariables,
  HeadCell,
} from "../models/app";
import { CreateAttachmentInput } from "../models/api";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();

  async function fetch(params: ListingVariables) {
    const { startIndex, limit } = params;

    try {
      const listing = await DataStore.query(
        Attachment as any,
        (model: any) => model.deleted("eq", "0"),
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;

    try {
      const single: Attachment | undefined = await DataStore.query(
        Attachment as any,
        id
      );

      return single;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateAttachmentInput = {
        mediaID: data.mediaID,
        fileUrl: data.fileUrl,
        filename: data.filename,
        filetype: data.filetype,
        fileSize: data.fileSize,
        alternativeText: data.alternativeText,
        caption: data.caption,
        description: data.description,
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      const attachment: Attachment = await DataStore.save(
        new Attachment(createInput as any)
      );

      return attachment;
    } catch (err) {
      throw err;
    }
  }

  async function update(params: UpdateVariables) {
    const { id, data } = params;

    try {
      const original = await get({ id });

      await DataStore.save(
        Attachment.copyOf(original!, (updated) => {
          updated.fileUrl = data.fileUrl ? data.fileUrl : original!.fileUrl;
          updated.alternativeText = data.alternativeText
            ? data.alternativeText
            : original!.alternativeText;
        })
      );

      return `${singleName} has been updated successfully`;
    } catch (err) {
      throw err;
    }
  }

  async function trash(id: Readonly<Record<string, any>>) {
    try {
      await DataStore.delete(id);

      return `${singleName} has been deleted successfully`;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function bulkTrash(params: AttachmentBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    return `${ids.size} ${listingName} items has been moved to trash`;
  }

  async function remove(params: GetVariables) {
    try {
      const toDelete: Attachment | undefined = await get(params);

      if (!toDelete) throw new Error("Item cannot be found!");

      await DataStore.delete(toDelete);

      return `${singleName} has been deleted successfully`;
    } catch (err: Error | any) {
      throw err;
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "category",
      numeric: false,
      disablePadding: false,
      label: "Category",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "category"];

  const api: any = {};

  api[`${listingName}Model`] = Attachment as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Attachment[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useResource;
